interface Props {
  color?: string
  className?: string
}

export function DeleteIcon({ color = 'gray', className }: Props): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" className={className}>
      <g id="delete_icon" transform="translate(-299.999 -561)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(299.999 561)"
          fill={color}
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(301 562)"
          fill={color}
          opacity="0"
        />
        <g
          id="Rectangle_4206"
          data-name="Rectangle 4206"
          transform="translate(303 565)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        >
          <path d="M0,0H12a0,0,0,0,1,0,0V11a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V0A0,0,0,0,1,0,0Z" stroke="none" />
          <path
            d="M1,.5H11a.5.5,0,0,1,.5.5V11A1.5,1.5,0,0,1,10,12.5H2A1.5,1.5,0,0,1,.5,11V1A.5.5,0,0,1,1,.5Z"
            fill="none"
          />
        </g>
        <rect
          id="Rectangle_4207"
          data-name="Rectangle 4207"
          width="16"
          height="1"
          transform="translate(301 565)"
          fill={color}
        />
        <rect
          id="Rectangle_4209"
          data-name="Rectangle 4209"
          width="1"
          height="7"
          transform="translate(307 568)"
          fill={color}
        />
        <rect
          id="Rectangle_4210"
          data-name="Rectangle 4210"
          width="1"
          height="7"
          transform="translate(310 568)"
          fill={color}
        />
        <g
          id="Rectangle_4208"
          data-name="Rectangle 4208"
          transform="translate(306 562)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        >
          <path d="M1,0H5A1,1,0,0,1,6,1V4A0,0,0,0,1,6,4H0A0,0,0,0,1,0,4V1A1,1,0,0,1,1,0Z" stroke="none" />
          <rect x="0.5" y="0.5" width="5" height="3" rx="0.5" fill="none" />
        </g>
      </g>
    </svg>
  )
}
