import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import * as Api from 'src/api'
import PortfolioIcon from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerPortfolioSectionsPage(): JSX.Element | null {
  const { pathname } = useLocation()
  const t = useTranslatable()
  const breadcrumbsItems = [{ page: `${t('portfolio:portfolio')}`, path: pathname }]
  return (
    <Section title={t('portfolio:portfolio')} icon={<PortfolioIcon />} breadcrubms={breadcrumbsItems}>
      <Helmet title={t('portfolio:portfolio')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </Section>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const sections = useApi({
    endpoint: Api.getLecturerPortfolioSections,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })
  return (
    <>
      {sections.data.data.map((section) => (
        <div key={section.id} className="mt-2 text-primaryTextColor">
          <Link to={`/${locale}/lecturer/portfolio/sections/${section.id}/cases`}>
            <div className="mb-3 ml-3 text-headline font-semibold text-primaryTextColor xxs:text-[20px] xs:text-[20px]">
              {section.name}
            </div>
          </Link>
          <Table.Table className="min-w-[768px]">
            <Table.Thead>
              <Table.Tr className="w-[100%">
                <Table.Th className="className='text-left' w-1/4">
                  <span>მიმაგრებული ქესიები</span>
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td className="text-left">9</Table.Td>
              </Table.Tr>
            </Table.Tbody>
          </Table.Table>
        </div>
      ))}
    </>
  )
}
