export function RedArrowIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.935" height="11.314" viewBox="0 0 12.935 11.314">
      <g id="Group_3" data-name="Group 3" transform="translate(-7 -3.536)">
        <rect
          id="Rectangle_26"
          data-name="Rectangle 26"
          width="12"
          height="1"
          transform="translate(19 9.692) rotate(180)"
          fill="#b80433"
        />
        <rect
          id="Rectangle_27"
          data-name="Rectangle 27"
          width="8"
          height="1"
          transform="translate(19.228 9.899) rotate(-135)"
          fill="#b80433"
        />
        <rect
          id="Rectangle_28"
          data-name="Rectangle 28"
          width="8"
          height="1"
          transform="translate(19.935 9.192) rotate(135)"
          fill="#b80433"
        />
      </g>
    </svg>
  )
}
