export interface Defered<T> {
  readonly promise: Promise<T>
  readonly reject: (reason?: any) => void
  readonly resolve: (value: T | PromiseLike<T>) => void
}

export function defered<T>(): Defered<T> {
  let resolveHandler: Defered<T>['resolve']
  let rejectHandler: Defered<T>['reject']

  const promise = new Promise<T>((resolve, reject) => {
    resolveHandler = resolve
    rejectHandler = reject
  })

  return {
    // @ts-expect-error ts issue
    resolve: resolveHandler,
    // @ts-expect-error ts issue
    reject: rejectHandler,
    promise,
  }
}
