export default function Calendar(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="calendar_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Rectangle_4175"
          data-name="Rectangle 4175"
          transform="translate(305 418)"
          className="fill-none stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="21" height="18" rx="3" stroke="none" />
          <rect x="1" y="1" width="19" height="16" rx="2" fill="none" />
        </g>
        <rect
          id="Rectangle_4177"
          data-name="Rectangle 4177"
          width="2"
          height="8"
          rx="1"
          transform="translate(310 415)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_4178"
          data-name="Rectangle 4178"
          width="2"
          height="8"
          rx="1"
          transform="translate(319 415)"
          className="fill-svgColor"
        />
        <g
          id="Ellipse_972"
          data-name="Ellipse 972"
          transform="translate(317 427)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <circle cx="6" cy="6" r="6" stroke="none" />
          <circle cx="6" cy="6" r="5" fill="none" />
        </g>
        <path
          id="Path_25009"
          data-name="Path 25009"
          d="M323,430.525V433l1.893.779"
          className="fill-none stroke-svgColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
