import React from 'react'

interface Props {
  children: React.ReactNode[] | React.ReactNode
  weekday: string
  index: number
  lastIndex: number
  currentDay: string | undefined
  date?: string
}

export function MySchedule({ currentDay, weekday, date, lastIndex, index, children }: Props): JSX.Element {
  return (
    <div>
      <div className="flex gap-3 pb-[5px]">
        <div className="mt-[3px] flex w-4 flex-col items-center">
          <div className="mb-2 inline-table min-h-[8px] min-w-[8px] items-center justify-center rounded-full border-DEFAULT border-seconderyRed">
            {currentDay === weekday ? <div className="rounded9-full m-[3px] size-2 bg-seconderyRed" /> : null}
          </div>
          {index === lastIndex ? null : <div className="h-full w-px bg-seconderyRed" />}
        </div>
        <div className="w-full pb-[11px] sm:w-full">
          <div className="mb-2 font-semibold text-primaryTextColor">
            {weekday} <span className="ml-[5px] font-normal text-captionColor">({date})</span>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}
