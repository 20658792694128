import * as React from 'react'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { Plus } from 'src/assets/icons/customIcons/Plus'
import { ProgramsIcon } from 'src/assets/icons/customIcons/page-icons/Programs'
import { errorMessage } from 'src/helpers/fns'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Select from 'src/views/components/Select'
import SubPageLayout from 'src/views/includes/SubPageLayout'

export default function LecturerCourseLayout(): JSX.Element | null {
  return (
    <ErrorBoundary errorElement={<ErrorElement />}>
      <PageContent />
    </ErrorBoundary>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const [searchParams, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const activeSemester = useActiveSemester()
  const semId = searchParams.get('semId') ?? activeSemester?.id
  const t = useTranslatable()
  const locale = useLocale()
  const { pathname } = useLocation()

  const { data: course, isValidating }: APIResponse<Api.Course | null, Api.getLecturerCourseErr> = useApi({
    endpoint: Api.getLecturerCourse,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semId!,
        },
        args: {
          id: courseId!,
        },
      }),
      [courseId, headers, semId]
    ),
    shouldFetch: semId != null,
    suspense: false,
  })

  const {
    data: semesters,
    error: semestersError,
  }: APIResponse<Api.getLecturerTeachesSemestersOk | null, Api.getLecturerTeachesSemestersErr> = useApi({
    endpoint: Api.getLecturerCourseTeachesSemesters,
    params: React.useMemo(() => ({ headers, args: { id: courseId! } }), [headers, courseId]),
    suspense: false,
  })

  function TopContent(): React.ReactElement | null {
    if (semestersError != null) {
      return (
        <NoContent
          header={t('error:records_not_found')}
          subHeader={errorMessage(semestersError)}
          image={Warning}
          marginTop="5"
        />
      )
    } else if (semesters == null) {
      return <Loader className="m-auto flex" />
    }

    const isMaterialsPage = pathname.includes('materials')
    const isDiscussionsPage = pathname.includes('discussions')

    return (
      <div className="z-10 my-3 flex flex-wrap justify-between xxs:justify-end xs:justify-end">
        <Select
          className="my-2 mr-2 w-full sm:w-[70%] xxs:max-w-full xs:max-w-full"
          value={semesters.filter((semester) => semester.id === semId)}
          options={semesters}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name + (option.isActive ? t('common:current') : '')}
          onChange={(option) => setSearchParams({ semId: option!.id })}
          isDisabled={isValidating}
        />
        {/* <div className='col flex justify-end'>
          <div className='mr-2'>
            <a target='_blank' href={(`https://staging.argus.ge/ge/lector/me/courseSilabusExport/${props.courseId}/${props.semId}/print`)} rel='noreferrer'>{t('common:print')}</a>
          </div>
          <div className=''>
            <a target='_blank' href={`https://staging.argus.ge/ge/lector/me/courseSilabusExport/${props.courseId}/${props.semId}/download`} rel='noreferrer'>{t('common:export')}</a>
          </div>
        </div> */}
        {(isMaterialsPage || isDiscussionsPage) && (
          <Link
            className="mt-2 sm:mt-0"
            to={
              isMaterialsPage
                ? `materials/add?semId=${searchParams.get('semId')!}`
                : `discussions/add?semId=${searchParams.get('semId')!}`
            }
          >
            <Button className="flex items-center" variant="blue">
              <Plus color="white" />
              <span className="ml-2">{isMaterialsPage ? t('course:add_materials') : t('group:add_post')}</span>
            </Button>
          </Link>
        )}
      </div>
    )
  }

  const subBreadcrumbsItems = [
    { page: `${t('course:learning_courses')}`, path: `/${locale}/lecturer/courses/teaches` },
    { page: course?.name, path: pathname },
  ]

  return (
    <SubPageLayout
      Icon={ProgramsIcon}
      filter={<TopContent />}
      title={course != null ? course.name : '...'}
      subBreadcrubms={subBreadcrumbsItems}
      pages={[
        {
          title: t('subPage:syllabus'),
          link: `syllabus?semId=${semId!}`,
        },
        {
          title: t('subPage:scores'),
          link: `scores?semId=${semId!}`,
        },
        {
          title: t('subPage:materials'),
          link: `materials?semId=${semId!}`,
          end: false,
        },
        {
          title: t('subPage:discussion'),
          link: `discussions?semId=${semId!}`,
          end: false,
        },
      ]}
    />
  )
}
