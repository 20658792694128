import * as React from 'react'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextInput from 'src/views/components/forms/formik/TextInput'

export function QueryForm(): React.ReactElement | null {
  const t = useTranslatable()
  // const pending = useStore(Api.getLecturerCourse.pending)
  // TODO
  const pending = false
  return (
    <fieldset disabled={pending} className="mb-2 flex flex-wrap justify-between md:!flex-nowrap">
      <FormError />
      <div className="w-full">
        <TextInput type="text" name="query" placeholder={t('common:enter_your_keyword')} searchField />
      </div>
      <Button variant="red" className="ml-0 w-[120px] md:ml-4" type="submit">
        {t('common:search')}
      </Button>
    </fieldset>
  )
}
