export default function ViewIcon(): JSX.Element {
  return (
    <svg id="view_icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <rect id="Rectangle_3629" data-name="Rectangle 3629" width="24" height="24" opacity="0" />
      <rect
        id="Rectangle_3630"
        data-name="Rectangle 3630"
        width="22"
        height="22"
        transform="translate(1 1)"
        opacity="0"
      />
      <g id="Path_25074" data-name="Path 25074" transform="translate(5 5)" fill="none">
        <path d="M7,0c6.1,0,11,7,11,7s-4.523,7-11,7S-4,7-4,7,.9,0,7,0Z" stroke="none" />
        <path
          d="M 7 1 C 2.46052360534668 1 -1.492246627807617 5.437176704406738 -2.758113861083984 7.020749568939209 C -1.565553665161133 8.608236312866211 2.170866012573242 13 7 13 C 8.315010070800781 13 9.655960083007812 12.67055034637451 10.98561000823975 12.0208101272583 C 12.07898998260498 11.4865198135376 13.16802978515625 10.7360897064209 14.22247982025146 9.790369987487793 C 15.40661430358887 8.72833251953125 16.28348541259766 7.652543067932129 16.75804138183594 7.020702838897705 C 15.49159526824951 5.436436653137207 11.53914070129395 1 7 1 M 7 0 C 13.09865951538086 0 18 7 18 7 C 18 7 13.47698020935059 14 7 14 C 0.5230197906494141 14 -4 7 -4 7 C -4 7 0.9013395309448242 0 7 0 Z"
          stroke="none"
          fill="gray"
        />
      </g>
      <g
        id="Ellipse_1000"
        data-name="Ellipse 1000"
        transform="translate(8 8)"
        fill="none"
        stroke="gray"
        strokeWidth="1"
      >
        <circle cx="4" cy="4" r="4" stroke="none" />
        <circle cx="4" cy="4" r="3.5" fill="none" />
      </g>
    </svg>
  )
}
