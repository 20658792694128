export function NewsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="news_icon" transform="translate(-305 -412)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 412)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Group_33" data-name="Group 33" transform="translate(-583.566 384.934)">
          <path
            id="Path_26"
            data-name="Path 26"
            d="M898.763,31.785l-6.47,8.023-2.516,1.527,1.9,3.271,2.576-1.491,10.3-1.41.225.462,2.714-1.583L901.1,29.36l-2.737,1.678Z"
            transform="translate(0.207 2.169)"
            fill="none"
            strokeLinejoin="round"
            strokeWidth="2"
            className="stroke-svgColor"
          />
          <path
            id="Path_27"
            data-name="Path 27"
            d="M907.5,42.735l-6.2-10.547"
            transform="translate(-2.457 1.564)"
            fill="none"
            strokeWidth="2"
            className="stroke-svgColor"
          />
          <path
            id="Path_28"
            data-name="Path 28"
            d="M893.125,42.937l1.919,3.286"
            transform="translate(-0.483 -1.032)"
            fill="none"
            className="stroke-svgColor"
            strokeWidth="2"
          />
          <path
            id="Path_29"
            data-name="Path 29"
            d="M903.782,46.353c.058.687-.387,3.183-2.725,3.183a2.815,2.815,0,0,1-2.939-2.33"
            transform="translate(-1.848 -1.857)"
            fill="none"
            className="stroke-svgColor"
            strokeWidth="2"
          />
        </g>
        <g id="Group_34" data-name="Group 34" transform="translate(0 -1)">
          <rect
            id="Rectangle_3655"
            data-name="Rectangle 3655"
            width="4"
            height="2"
            rx="1"
            transform="translate(322.943 420.414) rotate(-30)"
            className="fill-svgColor"
          />
          <rect
            id="Rectangle_3668"
            data-name="Rectangle 3668"
            width="4"
            height="2"
            rx="1"
            transform="translate(323.293 414.777) rotate(105)"
            className="fill-svgColor"
          />
          <rect
            id="Rectangle_3657"
            data-name="Rectangle 3657"
            width="4"
            height="2"
            rx="1"
            transform="matrix(0.966, 0.259, -0.259, 0.966, 325.136, 423.627)"
            className="fill-svgColor"
          />
        </g>
      </g>
    </svg>
  )
}
