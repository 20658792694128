export default function Statements(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="statement_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite stroke-[#707070]"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Path_25011" data-name="Path 25011" transform="translate(308 415)" className="fill-primaryWhite">
          <path
            d="M 16 23 L 2 23 C 1.448600053787231 23 1 22.55139923095703 1 22 L 1 2 C 1 1.448600053787231 1.448600053787231 1 2 1 L 11.58578014373779 1 L 17 6.414219856262207 L 17 22 C 17 22.55139923095703 16.55139923095703 23 16 23 Z"
            stroke="none"
          />
          <path
            d="M 2 2 L 2 22 L 16 22 L 16 6.828420639038086 L 11.17157936096191 2 L 2 2 M 2 0 L 12 0 L 18 6 L 18 22 C 18 23.10457038879395 17.10457038879395 24 16 24 L 2 24 C 0.8954296112060547 24 0 23.10457038879395 0 22 L 0 2 C 0 0.8954296112060547 0.8954296112060547 0 2 0 Z"
            stroke="none"
            className="fill-svgColor"
          />
        </g>
        <g id="Path_25012" data-name="Path 25012" transform="translate(319 415)" fill="none">
          <path d="M-1,0H1L7,6V8H-1Z" stroke="none" />
          <path
            d="M 1 2.828420162200928 L 1 6 L 4.171579837799072 6 L 1 2.828420162200928 M -1 0 L 1 0 L 7 6 L 7 8 L -1 8 L -1 0 Z"
            stroke="none"
            className="fill-svgColor"
          />
        </g>
        <rect
          id="Rectangle_4189"
          data-name="Rectangle 4189"
          width="4"
          height="2"
          rx="1"
          className="fill-svgColor"
          transform="translate(312 420)"
        />
        <rect
          id="Rectangle_4190"
          data-name="Rectangle 4190"
          width="10"
          height="2"
          rx="1"
          className="fill-svgColor"
          transform="translate(312 424)"
        />
        <rect
          id="Rectangle_4191"
          data-name="Rectangle 4191"
          width="10"
          height="2"
          rx="1"
          className="fill-svgColor"
          transform="translate(312 428)"
        />
        <rect
          id="Rectangle_4192"
          data-name="Rectangle 4192"
          width="10"
          height="2"
          rx="1"
          transform="translate(312 432)"
          className="fill-svgColor"
        />
      </g>
    </svg>
  )
}
