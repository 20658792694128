import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { format, formatDistance, parseISO } from 'date-fns'
import * as React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import CommentIcon from 'src/assets/icons/customIcons/Comment'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useTheme } from 'src/state/providers/Theme'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import UserPhoto from 'src/views/components/UserPhoto'
import Swal from 'sweetalert2'
import Comments from './Comments'

interface Props {
  readonly refetchPosts: () => any
  readonly post: Api.CoursePost
}

export default function Post({ post, refetchPosts }: Props): JSX.Element {
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const semId = searchParams.get('semId')
  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const t = useTranslatable()
  const localeDate = useDateLocale()
  const locale = useLocale()
  const [clickedComment, setClickedComment] = React.useState(false)
  const theme = useTheme()
  const [commentsCount, setCommentsCount] = React.useState(post.commentsCount)
  const deletePost = React.useCallback(async () => {
    try {
      await Api.deleteLecturerCoursesDiscussions({
        headers,
        body: {
          semId: semId!,
        },
        args: {
          id: params.courseId!,
          postId: post.id,
        },
      })
      refetchPosts()

      toast.success(t('discussion:post_successfully_deleted'))
    } catch (error) {
      toast.error(t('discussion:post_not_deleted'))
    }
  }, [headers, params.courseId, post.id, refetchPosts, semId, t])

  return (
    <React.Fragment key={post.id}>
      <section className="my-4 flex flex-col items-start justify-between">
        <section className="flex w-full items-start justify-between">
          <UserPhoto user={post.author} />

          <div className="ml-[12px] w-full flex-col">
            <div className="flex w-full flex-col rounded-card bg-card p-3 py-2">
              <div className="mb-2 flex w-full flex-wrap items-center justify-between dark:text-white">
                <h5 className="font-bold">{post.author?.fullName}</h5>
                <div className="flex items-center">
                  <h5 className="text-[#00000568]">#{post.number}</h5>
                  <div className="ml-4 flex items-center justify-center">
                    <Link
                      className="mr-2"
                      to={`/${locale}/lecturer/courses/${courseId!}/discussions/edit/${post.id}?semId=${semId!}`}
                    >
                      <FontAwesomeIcon icon={ProRegularSvgIcons.faPencil} color="gray" />
                    </Link>
                    <button
                      className="rounded-full p-2 hover:bg-hover-icon"
                      onClick={() => {
                        void (async () => {
                          const alert = await Swal.fire({
                            title: t('common:do_you_really_want_to_delete'),
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#0D6EFD',
                            cancelButtonColor: '#6C757D',
                            confirmButtonText: t('common:confirm'),
                            cancelButtonText: t('common:cancel'),
                          })
                          if (alert.isConfirmed) {
                            void deletePost()
                          }
                        })()
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              </div>
              <div className="dark:text-white" dangerouslySetInnerHTML={{ __html: post.body }} />
            </div>
            <div className="mt-1 flex flex-wrap text-captionColor">
              <button
                data-tooltip-id={post.id}
                onClick={() => setClickedComment(!clickedComment)}
                className="mr-4 flex items-center"
              >
                <div
                  className={classNames('flex items-center', {
                    'cursor-not-allowed opacity-50': !post.isCommentingEnabled,
                  })}
                >
                  <span className="mr-1">
                    <CommentIcon />
                  </span>
                  <span className="text-captionColor">
                    {t('discussion:comments')} ({commentsCount})
                  </span>
                </div>
                {!post.isCommentingEnabled && (
                  <Tooltip id={post.id} place="top" variant={theme === 'dark' ? 'dark' : 'light'} className="mb-1 p-1">
                    <p className="mb-0" key={post.createdAt}>
                      {t('discussion:comments_disabled')}
                    </p>
                  </Tooltip>
                )}
              </button>
              <button
                className="flex items-center"
                data-tooltip-id={post.createdAt}
                onClick={(e) => e.preventDefault()}
              >
                <ClockIcon />{' '}
                <span className="ml-1 text-captionColor">
                  {formatDistance(new Date(post.createdAt), new Date(), { addSuffix: true, locale: localeDate })}
                </span>
                <Tooltip
                  id={post.createdAt}
                  place="top"
                  variant={theme === 'dark' ? 'dark' : 'light'}
                  className="mb-1 p-1"
                >
                  <p className="mb-0" key={post.createdAt}>
                    {format(parseISO(post.createdAt), 'd MMMM yyyy, HH:mm', {
                      locale: localeDate,
                    })}
                  </p>
                </Tooltip>
              </button>
              <div className="ml-2">
                {post.isVisibleToAll ? (
                  <FontAwesomeIcon icon={FreeSolidSvgIcons.faGlobeAmericas} data-tooltip-id-="isVisibleToAll" />
                ) : (
                  <>
                    <FontAwesomeIcon icon={FreeSolidSvgIcons.faUserGroup} data-tooltip-id="!isVisibleToAll" />
                    {post.groups.map((g) => (
                      <span key={g.id} className="ml-1 rounded-md bg-card p-1 text-caption">
                        {g.name}
                      </span>
                    ))}
                  </>
                )}
                <Tooltip
                  id="isVisibleToAll"
                  place="top"
                  variant={theme === 'dark' ? 'dark' : 'light'}
                  className="mb-1 p-1"
                >
                  <p className="mb-0">{t('group:all_of_your_groups')}</p>
                </Tooltip>
                <Tooltip
                  id="!isVisibleToAll"
                  place="top"
                  variant={theme === 'dark' ? 'dark' : 'light'}
                  className="mb-1 p-1"
                >
                  <p className="mb-0" key={post.createdAt}>
                    {t('group:only_selected_groups')}
                  </p>
                </Tooltip>
              </div>
            </div>
          </div>
        </section>
        {post.isCommentingEnabled && clickedComment && (
          <React.Suspense fallback={<Loader className="m-auto flex" />}>
            <ErrorBoundary errorElement={<ErrorElement />}>
              <Comments post={post} setCommentsCount={setCommentsCount} />
            </ErrorBoundary>
          </React.Suspense>
        )}
      </section>
    </React.Fragment>
  )
}
