export function FeedbackIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" data-testid="feedbackMenuIcon">
      <g id="home_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g id="Union_3" data-name="Union 3" transform="translate(41 605)" fill="none">
          <path d="M6-574H3a3,3,0,0,1-3-3v-8a3,3,0,0,1,3-3H13a3,3,0,0,1,3,3v8a3,3,0,0,1-3,3H10l-2,2Z" stroke="none" />
          <path
            d="M 8.000031471252441 -573.4146118164062 L 9.58568000793457 -575.000244140625 L 12.99960041046143 -575.000244140625 C 14.10272979736328 -575.000244140625 15.00020027160645 -575.8972778320312 15.00020027160645 -576.9999389648438 L 15.00020027160645 -585 C 15.00020027160645 -586.1026611328125 14.10272979736328 -586.9996948242188 12.99960041046143 -586.9996948242188 L 2.99970006942749 -586.9996948242188 C 1.89706015586853 -586.9996948242188 1.00000011920929 -586.1026611328125 1.00000011920929 -585 L 1.00000011920929 -576.9999389648438 C 1.00000011920929 -575.8972778320312 1.89706015586853 -575.000244140625 2.99970006942749 -575.000244140625 L 6.414100170135498 -575.000244140625 L 8.000031471252441 -573.4146118164062 M 8.000100135803223 -572.0004272460938 L 5.999939918518066 -574.000244140625 L 2.99970006942749 -574.000244140625 C 1.342800140380859 -574.000244140625 1.358032193365943e-07 -575.343017578125 1.358032193365943e-07 -576.9999389648438 L 1.358032193365943e-07 -585 C 1.358032193365943e-07 -586.6569213867188 1.342800140380859 -587.9996948242188 2.99970006942749 -587.9996948242188 L 12.99960041046143 -587.9996948242188 C 14.6564998626709 -587.9996948242188 16.00020027160645 -586.6569213867188 16.00020027160645 -585 L 16.00020027160645 -576.9999389648438 C 16.00020027160645 -575.343017578125 14.6564998626709 -574.000244140625 12.99960041046143 -574.000244140625 L 9.999899864196777 -574.000244140625 L 8.000100135803223 -572.0004272460938 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <rect
          id="Rectangle_38"
          data-name="Rectangle 38"
          width="10"
          height="1"
          rx="0.5"
          transform="translate(44 20)"
          fill="gray"
        />
        <rect
          id="Rectangle_39"
          data-name="Rectangle 39"
          width="10"
          height="1"
          rx="0.5"
          transform="translate(44 23)"
          fill="gray"
        />
        <rect
          id="Rectangle_40"
          data-name="Rectangle 40"
          width="5"
          height="1"
          rx="0.5"
          transform="translate(44 26)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
