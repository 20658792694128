import { type DayCellContentArg, type EventApi } from '@fullcalendar/core'
import fullcalendarDaygrid from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/react'
import { format } from 'date-fns'
import { useFormikContext } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { Plus } from 'src/assets/icons/customIcons/Plus'
import Calendar from 'src/assets/icons/customIcons/page-icons/CalendarIcon'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { formatDatelocale, shortenString } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useDateLocale, useFullCalendarLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import { Section } from 'src/tailwind/components/Section'
import CalendarPopoverComponent from 'src/views/components/CalendarPopoverComponent'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import DateInput from 'src/views/components/forms/formik/DateInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Swal from 'sweetalert2'
import Loader from '../../../../components/Loader'

interface FormikValues {
  readonly name: string
  readonly nameEn: string
  readonly date: Date
  readonly courseId: string
  readonly groupId: string
  readonly criteriaId: string | undefined
}

export default function LecturerCalendarPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('calendar:calendar')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const { pathname } = useLocation()
  const { data: calendarItems, mutate: refetchItems } = useApi({
    endpoint: Api.getLecturerCalendar,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const t = useTranslatable()
  const [show, setShow] = React.useState(false)
  const [date, setDate] = React.useState(new Date())
  const fullCalendarLocale = useFullCalendarLocale()
  const dateLocale = useDateLocale()
  const theme = useTheme()
  const initialValues: FormikValues = {
    name: '',
    nameEn: '',
    date,
    courseId: '',
    groupId: '',
    criteriaId: undefined,
  }

  const submit = React.useCallback(
    async (values: FormikValues): Promise<void> => {
      await Api.postLecturerCalendar({
        headers,
        body: {
          name: values.name,
          nameEn: values.nameEn,
          date: format(new Date(date), 'dd/MM/yyyy'),
          courseId: values.courseId,
          groupId: values.groupId,
          criteriaId: values.criteriaId,
        },
      })
      void refetchItems()
      setShow(false)

      toast.success(t('calendar:event_added'))
    },
    [date, headers, refetchItems, t]
  )

  const breadcrumbsItems = [{ page: `${t('calendar:calendar')}`, path: pathname }]

  return (
    <Section
      title={t('calendar:calendar')}
      icon={<Calendar />}
      rightElement={
        <Button variant="blue" className="flex px-3 py-[14px]" onClick={() => setShow(true)}>
          <Plus color="white" />
          <span className="ml-2"> {t('common:add')}</span>
        </Button>
      }
      breadcrubms={breadcrumbsItems}
    >
      <FullCalendar
        buttonText={{
          today: t('calendar:today'),
          list: t('common:day'),
        }}
        events={calendarItems.map((calendarItem) => {
          const start =
            calendarItem.eventType !== 'schedule'
              ? format(new Date(formatDatelocale(calendarItem.startDate) as Date), 'yyyy-MM-dd')
              : format(new Date(calendarItem.startDate), 'yyyy-MM-dd')
          const title =
            calendarItem.eventType !== 'schedule'
              ? calendarItem.title
              : format(new Date(calendarItem.startDate), 'HH:mm') + ' ' + calendarItem.title

          return {
            color:
              calendarItem.eventType === 'holiday'
                ? '#b94a48'
                : calendarItem.eventType === 'schedule'
                  ? '#FFB752'
                  : '#3A87AD',
            start,
            title,
            eventId: calendarItem.id,
            eventType: calendarItem.eventType,
          }
        })}
        plugins={[fullcalendarDaygrid, listPlugin]}
        headerToolbar={{
          left: 'dayGridMonth,listWeek,today',
          right: 'prev,title,next',
        }}
        initialView="dayGridMonth"
        eventClassNames="day-event"
        titleFormat={({ date }) => {
          return format(
            new Date(date.year, date.month, date.day, date.hour, date.minute, date.second, date.millisecond),
            'LLLL yyyy',
            { locale: dateLocale }
          )
        }}
        dayHeaderFormat={({ date }) => {
          return format(
            new Date(date.year, date.month, date.day, date.hour, date.minute, date.second, date.millisecond),
            'EEE',
            { locale: dateLocale }
          )
        }}
        views={{
          list: {
            eventContent: function (arg) {
              return <EventContent event={arg.event} revalidate={refetchItems} />
            },
          },
        }}
        locale={fullCalendarLocale}
        eventContent={({ event }) => <EventContent event={event} revalidate={refetchItems} dayGrid />}
        dayMaxEvents={2}
        dayCellContent={(info: DayCellContentArg) =>
          info.isPast ? (
            <span>{info.dayNumberText}</span>
          ) : (
            <DayCellContent
              info={info}
              onClick={() => {
                setShow(true)
                setDate(info.date)
              }}
            />
          )
        }
      />
      <Modal
        title={t('calendar:add_event')}
        icon={
          <div className="mr-2">
            <Plus color={theme === 'dark' ? '#FFF' : '#000'} />
          </div>
        }
        isModalOpen={show}
        onClose={() => setShow(false)}
      >
        <div className="min-h-[540px]">
          {show && (
            <Form
              initialValues={initialValues}
              onSubmit={submit}
              classNames={{
                form: 'px-1 pb-4',
              }}
              isConfirmable
            >
              <CalendarForm />
            </Form>
          )}
        </div>
      </Modal>
    </Section>
  )
}

interface DayCellContentProps {
  readonly info: DayCellContentArg
  readonly onClick: React.MouseEventHandler<HTMLButtonElement>
}

function DayCellContent({ info, onClick }: DayCellContentProps): React.ReactElement {
  const theme = useTheme()

  return (
    <div className="flex pr-[2px] align-baseline">
      <span className="pt-[2px]">{info.dayNumberText}</span>
      <button className="ml-1 p-0 text-primaryTextColor xxs:mb-2 xs:mb-2" onClick={onClick}>
        <Plus color={theme === 'dark' ? '#FFF' : '#000'} className="xxs:w-[10px] xs:w-[10px]" />
      </button>
    </div>
  )
}

interface EventContentProps {
  readonly event: EventApi
  readonly revalidate: () => unknown
  readonly dayGrid?: boolean
}

function EventContent(props: EventContentProps): React.ReactElement {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()

  const deleteEvenet = React.useCallback(
    async (id: string): Promise<void> => {
      try {
        await Api.deleteLecturerCalendar({
          headers,
          args: {
            id,
          },
        })
        void props.revalidate()

        toast.success(t('calendar:event_deleted'))
      } catch (error) {
        toast.error(t('calendar:event_not_deleted'))
      }
    },
    [headers, props, t]
  )

  return (
    <div className="flex flex-wrap items-center overflow-hidden">
      {props.event.extendedProps.eventType !== 'holiday' && (
        <>
          <CalendarPopoverComponent
            placement="top"
            label={props.dayGrid ? shortenString(props.event.title, 15) : props.event.title}
          >
            {props.event.title}
          </CalendarPopoverComponent>
          <button
            className="sm:x-auto ml-auto"
            onClick={() => {
              void (async () => {
                const alert = await Swal.fire({
                  title: t('common:do_you_really_want_to_delete'),
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#0D6EFD',
                  cancelButtonColor: '#6C757D',
                  confirmButtonText: t('common:confirm'),
                  cancelButtonText: t('common:cancel'),
                })
                if (alert.isConfirmed) {
                  try {
                    await deleteEvenet(props.event.extendedProps.eventId)
                  } catch (error) {
                    console.error(error)
                  }
                }
              })()
            }}
          >
            <DeleteIcon color="white" className="w-[14px] xxs:w-[10px] xs:w-[10px]" />
          </button>
        </>
      )}
      {props.event.extendedProps.eventType === 'holiday' && (
        <div className="xxs:text-tiny xs:text-tiny">
          <CalendarPopoverComponent placement="top" label={shortenString(props.event.title, 15)}>
            {props.event.title}
          </CalendarPopoverComponent>
        </div>
      )}
    </div>
  )
}

function CalendarForm(): React.ReactElement {
  const headers = useAuthenticatedHeaders()
  const activeSemester = useActiveSemester()?.id
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()

  const { data: courses, isValidating: coursesPending } = useApi({
    endpoint: Api.getLecturerCoursesTeaches,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: activeSemester!,
        },
      }),
      [activeSemester, headers]
    ),

    shouldFetch: activeSemester != null,
  })

  const { data: teachingGroups, isValidating: TeachingGroupsPending } = useApi({
    endpoint: Api.getLecturerTeachingGroups,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: formik.values.courseId,
        },
        query: {
          semId: activeSemester!,
        },
      }),
      [activeSemester, formik.values.courseId, headers]
    ),
    shouldFetch: formik.values.courseId.length > 0 && activeSemester != null,
    suspense: false,
  })

  const { data: GroupsPaper, isValidating: GroupsPaperPending } = useApi({
    endpoint: Api.getLecturerGroupsPaper,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: formik.values.courseId,
          groupId: formik.values.groupId,
        },
        query: {
          semId: activeSemester!,
        },
      }),
      [activeSemester, formik.values.courseId, formik.values.groupId, headers]
    ),
    shouldFetch: formik.values.groupId.length > 0 && activeSemester != null && formik.values.courseId != null,
    suspense: false,
  })

  return (
    <>
      <FormError />
      <TextInput type="text" name="name" label={t('common:name')} required />
      <TextInput type="text" name="nameEn" label={t('common:name_en')} required />
      <DateInput name="date" label={t('common:date')} minDate={new Date().toString()} required />
      <SelectInput
        placeholder={t('course:select_course')}
        name="courseId"
        options={courses?.map((item) => ({ value: item.id, label: item.name })) ?? []}
        label={t('course:course')}
        required
        disabled={coursesPending}
      />
      <SelectInput
        placeholder={t('group:select_group')}
        label={t('group:group')}
        name="groupId"
        options={teachingGroups?.map((group) => ({ value: group.id, label: group.name })) ?? []}
        disabled={teachingGroups == null}
        required
        isLoading={TeachingGroupsPending}
      />
      <SelectInput
        placeholder={t('calendar:select_criteria')}
        label={t('calendar:criteria')}
        name="criteriaId"
        options={GroupsPaper?.criterions?.map((paper) => ({ value: paper.id, label: paper.fullName })) ?? []}
        disabled={GroupsPaper == null}
        isLoading={GroupsPaperPending}
      />
      <FormSubmit
        label={t('common:save')}
        classNames={{
          button: 'btn-success',
        }}
      />
    </>
  )
}
