export default function Catalog(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="catalog_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Rectangle_3707"
          data-name="Rectangle 3707"
          transform="translate(313 421)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="16" height="18" rx="2" stroke="none" />
          <rect x="1" y="1" width="14" height="16" rx="1" fill="none" />
        </g>
        <g
          id="Rectangle_3703"
          data-name="Rectangle 3703"
          transform="translate(305 415)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="16" height="18" rx="2" stroke="none" />
          <rect x="1" y="1" width="14" height="16" rx="1" fill="none" />
        </g>
        <rect
          id="Rectangle_3704"
          data-name="Rectangle 3704"
          width="8"
          height="2"
          rx="1"
          transform="translate(309 423)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_3705"
          data-name="Rectangle 3705"
          width="8"
          height="2"
          rx="1"
          transform="translate(309 427)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_3706"
          data-name="Rectangle 3706"
          width="8"
          height="2"
          rx="1"
          transform="translate(309 419)"
          className="fill-svgColor"
        />
      </g>
    </svg>
  )
}
