import classnames from 'classnames'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { ClockIcon } from 'src/assets/icons/customIcons/Clock'
import { CourseIcon } from 'src/assets/icons/customIcons/Course'
import { GroupIcon } from 'src/assets/icons/customIcons/Group'
import { InfoIcon } from 'src/assets/icons/customIcons/Info'
import { PinIcon } from 'src/assets/icons/customIcons/Pin'
import CalendarIcon from 'src/assets/icons/customIcons/page-icons/Calendar'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { MySchedule } from 'src/tailwind/components/MySchedule'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from '../../../../components/NoContent'

export default function LecturerSchedulePage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('schedule:my_schedule')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const theme = useTheme()
  const result = useApi({
    endpoint: Api.getLecturerSchedulesCurrent,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const weekdays = result.data
  const weekDays = [
    t('calendar:sunday'),
    t('calendar:monday'),
    t('calendar:tuesday'),
    t('calendar:wednesday'),
    t('calendar:thursday'),
    t('calendar:friday'),
    t('calendar:saturday'),
  ]
  const d = new Date()
  const currentDay = weekDays[d.getDay()]
  const { pathname } = useLocation()

  const breadcrumbsItems = [{ page: `${t('schedule:my_schedule')}`, path: pathname }]

  return (
    <Section title={t('schedule:my_schedule')} icon={<CalendarIcon />} breadcrubms={breadcrumbsItems}>
      {weekdays.length > 0 ? (
        <>
          {weekdays.map((weekday, i, whole) => (
            <MySchedule
              key={i}
              weekday={weekday.name}
              index={i}
              currentDay={currentDay}
              lastIndex={whole.length - 1}
              date={weekday.date}
            >
              <Table.Table
                className="w-full border-separate border-spacing-y-2 px-3 py-1"
                style={{
                  backgroundColor:
                    currentDay === weekday.name && theme === 'light'
                      ? '#dee4fa'
                      : currentDay === weekday.name && theme === 'dark'
                        ? '#262931'
                        : '',
                }}
              >
                <Table.Thead>
                  <Table.Tr className="border-none text-caption">
                    <Table.Th className="w-[131px] pb-3">
                      <div className="flex items-center">
                        <ClockIcon />
                        <span className="ml-2 font-standard">{t('common:time')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="pb-3">
                      <div className="flex items-center">
                        <PinIcon />
                        <span className="ml-2 font-standard">{t('common:room')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="w-[300px] pb-3">
                      <div className="flex items-center">
                        <CourseIcon />
                        <span className="ml-2 font-standard">{t('course:course')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="w-[120px] pb-3">
                      <div className="flex items-center">
                        <GroupIcon />
                        <span className="ml-2 font-standard">{t('group:group')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="w-[120px] pb-3">
                      <div className="flex items-center">
                        <GroupIcon />
                        <span className="ml-2 font-standard">{t('common:type')}</span>
                      </div>
                    </Table.Th>
                    <Table.Th className="pb-3">
                      <div className="flex items-center">
                        <InfoIcon />
                        <span className="ml-2 font-standard">{t('common:information')}</span>
                      </div>
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody className="align-top">
                  {weekday.schedules.map((schedule, i) => (
                    <ScheduleRow key={schedule.id} schedule={schedule} i={i} schedules={weekday.schedules} />
                  ))}
                </Table.Tbody>
              </Table.Table>
            </MySchedule>
          ))}
        </>
      ) : (
        <NoContent
          image={Warning}
          header={t('schedule:your_schedule_is_empty')}
          subHeader={t('course:you_have_no_selected_courses_in_current_semester')}
        />
      )}
    </Section>
  )
}

interface ScheduleRowProps {
  schedule: Api.Schedule
  i: number
  schedules: readonly Api.Schedule[]
}

function ScheduleRow({ schedule, i, schedules }: ScheduleRowProps): JSX.Element {
  const locale = useLocale()
  const t = useTranslatable()
  const theme = useTheme()

  return (
    <Table.Tr
      data-tooltip-id={schedule.id}
      className={classnames('w-full border-none duration-150 ease-in-out hover:bg-black/5', {
        'border-spacing-y-3 bg-primaryRed/10 hover:!bg-primaryRed/30': schedule.duplicate != null,
      })}
    >
      <Table.Td className={`rounded-l-lg !py-[12px] ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        {schedule.hour.times}
        {schedule.duplicate != null && (
          <Tooltip
            id={schedule.id}
            variant={theme === 'dark' ? 'dark' : 'light'}
            clickable
            globalCloseEvents={{ clickOutsideAnchor: true, resize: true }}
          >
            {t('course:lecture_conflict')}
            {schedules.find((scheduleItem) => scheduleItem.id === schedule.duplicate!.id)?.course.fullName}
          </Tooltip>
        )}
      </Table.Td>
      <Table.Td className={`!py-[12px] ${schedules.length - 1 === i ? 'min-w-[103px] !pb-6' : ''}`}>
        {schedule.locationName}
      </Table.Td>
      <Table.Td className={`!py-[12px] ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        <Link
          className="text-m align-middle text-primaryBlueLink"
          to={`/${locale}/lecturer/courses/${schedule.course.id}/syllabus`}
        >
          {schedule.course.fullName}
        </Link>
      </Table.Td>
      <Table.Td className={`!py-[12px] ${schedules.length - 1 === i ? '!pb-6' : ''}`}>{schedule.group.name}</Table.Td>
      <Table.Td className="!py-[12px]">{schedule.lectureTypeName}</Table.Td>
      <Table.Td className={`rounded-r-lg !py-[12px] ${schedules.length - 1 === i ? '!pb-6' : ''}`}>
        {schedule.info}
      </Table.Td>
    </Table.Tr>
  )
}
