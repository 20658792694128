import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import { Plus } from 'src/assets/icons/customIcons/Plus'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { formatFileSize } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import FileIcon from 'src/views/components/FileIcon'
import UserPhoto from 'src/views/components/UserPhoto'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerPortfolioCasePage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:case')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const { pathname } = useLocation()
  const t = useTranslatable()
  const locale = useLocale()

  const data = useApi({
    endpoint: Api.getLecturerPortfolioSectionCase,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          SectionId: params.sectionId!,
          id: params.caseId!,
        },
      }),
      [headers, params.caseId, params.sectionId]
    ),
  })
  const breadcrumbsItems = [
    { page: `${t('portfolio:portfolio')}`, path: `/${locale}/lecturer/portfolio` },
    { page: `${t('portfolio:case')}`, path: pathname },
  ]

  return (
    <Section title={t('portfolio:portfolio_work')} icon={<Portfolio />} breadcrubms={breadcrumbsItems}>
      <>
        {(() => {
          if (data.data.manage?.canSubmitScore === true) {
            return (
              <div className="!mb-9 flex items-center justify-end">
                <Link to="edit">
                  <Button variant="blue" className="flex items-center">
                    <Plus color="white" /> <span className="ml-2"> {t('portfolio:evaluation')}</span>
                  </Button>
                </Link>
                {/* <Button type="submit" className="ml-2" variant="blue">
                  {t('portfolio:add_work')}
                </Button>
                <Button type="submit" className="ml-2" variant="blue">
                  {t('portfolio:reject')}
                </Button> */}
              </div>
            )
          }
        })()}
        <div className="text-primaryTextColor">
          {data != null && (
            <>
              <div className="mb-6">
                <p className="mb-5 text-headline">{t('portfolio:work') + ` ${data.data.id}`}</p>
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_description') + `: `}
                </h2>
                {data.data.text != null ? (
                  <span dangerouslySetInnerHTML={{ __html: data.data.text }} />
                ) : (
                  <span> - </span>
                )}
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_competencies') + `: `}
                </h2>
                {data.data.competencies !== null ? (
                  data.data.competencies?.map((competency, index) => (
                    <span key={competency.id}> {(index !== 0 ? ', ' : '') + competency.name}</span>
                  ))
                ) : (
                  <span> - </span>
                )}
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:mentor') + `: `}
                </h2>
                {data.data.mentor != null ? (
                  <p className="text-title">
                    <UserPhoto className="scale-[0.7]" user={data.data.mentor} />
                    <Link
                      to={`/${locale}/users/${data.data.student?.uid}`}
                      className="ml-2 whitespace-nowrap align-middle !no-underline hover:underline"
                      data-tooltip-id={`${data.data.id}-lecturer`}
                    >
                      <span className="ml-2">{data.data.mentor?.fullName}</span>
                    </Link>
                  </p>
                ) : (
                  <span> - </span>
                )}
              </div>
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_status') + `: `}
                </h2>
                {data.data.status != null ? (
                  <div>
                    {data.data.status === 'STATUS_PENDING' && <span>{t('portfolio:status_pending')}</span>}
                    {data.data.status === 'STATUS_EVALUATED' ? <span>{t('portfolio:status_evaluated')}</span> : null}
                    {data.data.status === 'STATUS_REJECTED' && <span>{t('portfolio:status_rejected')}</span>}
                  </div>
                ) : (
                  <span> - </span>
                )}
              </div>
              <div className="mb-6">
                <h2 className="works-section mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('file:attached_files') + `: `}
                </h2>
                {data.data.mediaFiles?.length === 0 ? (
                  <p className="syllabus-content text-title">
                    {data.data.mediaFiles !== null && data.data.mediaFiles.length > 0 ? (
                      <div className="row-auto my-3">
                        <div className="col-auto mb-2">
                          {data.data.mediaFiles.map((file) =>
                            data.data.mediaFiles != null && data.data.mediaFiles.length > 0 ? (
                              <div key={file.id} className="mr-4 flex">
                                <FileIcon type={file.extension} />{' '}
                                <a
                                  href={`/${locale}/media-files/${file.id}/download`}
                                  className="mx-2 dark:text-white"
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {file.originalName}
                                </a>
                                <span className="text-sm text-captionColor">({formatFileSize(file.size)})</span>
                              </div>
                            ) : (
                              <span key={file.id}> - </span>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      <span> - </span>
                    )}
                  </p>
                ) : null}
              </div>
              <div className="mb-6">
                <h2 className="works-section mb-3 border-b border-borderGrey py-2 !text-captionColor">
                  {t('portfolio:work_feedback') + `: `}
                </h2>
                {data.data.feedback != null ? (
                  <p className="syllabus-content text-title">{data.data.feedback}</p>
                ) : (
                  <span> - </span>
                )}
              </div>
            </>
          )}
        </div>
      </>
    </Section>
  )
}
