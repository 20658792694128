import { type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { EmailIcon } from 'src/assets/icons/customIcons/Email'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormSubmit } from 'src/views/components/forms/formik/FormSubmit'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import Loader from 'src/views/components/Loader'

import * as Yup from 'yup'

interface FormikValues {
  readonly body: string
}

export default function RecommendationReplyPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('message:messages_compose')} />
      <React.Suspense fallback={<Loader className="mx-auto flex" style={{ height: '100vh' }} />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const headers = useAuthenticatedHeaders()
  const { senderProfileId, semesterId } = useParams()
  const auth = useAuth()
  const t = useTranslatable()
  const locale = useLocale()
  const navigate = useNavigate()

  const recommendation = useApi({
    endpoint: Api.getRecommendation,
    suspense: false,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          senderProfileId: senderProfileId!,
        },
        query: {
          semId: semesterId!,
        },
      }),
      [headers, senderProfileId, semesterId]
    ),
  })

  const student = useApi({
    endpoint: Api.getRecommendationStudentInfo,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          senderProfileId: senderProfileId!,
        },
        query: {
          semId: semesterId!,
        },
      }),
      [headers, senderProfileId, semesterId]
    ),
  })
  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/messages/inbox`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])
  if (auth.state !== 'Authenticated') return null

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const submit = React.useCallback(
    async ({ body }: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> => {
      try {
        await Api.postLecturerRecommendations({
          headers,
          args: {
            senderProfileId: senderProfileId!,
          },
          body: {
            text: body,
          },
        })
        formikHelpers.resetForm()
        setSuccessfullySubmited(true)
        toast.success(t('feedback:recommendation_success_feedback'))
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, senderProfileId, t]
  )

  return (
    <Section title={t('message:messages_compose')} icon={<EmailIcon />}>
      <h3 className="mb-[15px] flex justify-end border-b border-borderGrey pb-[10px] text-section">
        {student.data?.fullName}
      </h3>
      <div className="my-9 flex justify-end">
        <dl className="dl-horizontal dl-wide text-base">
          <div className="flex flex-col">
            <div className="col-span-1 mb-[10px] flex flex-col justify-end md:mb-0 md:flex-row">
              <dt className="mr-[9px] w-full text-bodyText text-primaryTextColor md:w-[300px] md:text-right">
                {t('faculty:major_faculty')}:
              </dt>
              <dd className="text-bodyText text-gray-700 dark:text-[#D9D9D9]">
                {student.data.profilePrograms.faculty.name}
              </dd>
            </div>

            <div className="col-span-1 mb-[10px] flex flex-col justify-end md:mb-0 md:flex-row">
              <dt className="mr-[9px] w-full text-bodyText text-primaryTextColor md:w-[300px] md:text-right">
                {t('faculty:preferred_major_program')}:
              </dt>
              <dd className="text-bodyText text-gray-700 dark:text-[#D9D9D9]">
                {student.data.profilePrograms.customProgram.name}
              </dd>
            </div>

            <div className="col-span-1 mb-[10px] flex flex-col justify-end md:mb-0 md:flex-row">
              <dt className="mr-[9px] w-full text-bodyText text-primaryTextColor md:w-[300px] md:text-right">
                {t('faculty:preferred_minor_program')}:
              </dt>
              <dd className="text-bodyText text-gray-700 dark:text-[#D9D9D9]">
                {student.data.profilePrograms.customProgram.minorDescription}
              </dd>
            </div>
          </div>
        </dl>
      </div>
      <div className="rounded-lg text-primaryTextColor">
        <Form
          initialValues={{ body: recommendation?.data?.textFragment ?? '' }}
          validationSchema={Yup.object().shape({
            body: Yup.string().required(t('message:body_is_required')),
          })}
          onSubmit={submit}
          isConfirmable={!successfullySubmited}
        >
          <TextareaInput
            name="body"
            label={t('recommendation:text_asking_for_a_recommendation')}
            className="h-[260px] w-full rounded-md border border-gray-300 px-4 py-2 text-base"
          />
          <FormSubmit
            label={t('message:send')}
            classNames={{
              root: 'flex justify-end my-3 mx-4 ',
            }}
          />
        </Form>
      </div>
    </Section>
  )
}
