import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { type CoursePost } from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'

import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Post from './Post'

export default function LectureCoursesDiscussionsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('discussion:discussion')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const [searchParams] = useSearchParams()
  const semId = searchParams.get('semId')
  const headers = useAuthenticatedHeaders()
  const params = useParams()

  const t = useTranslatable()

  const { data: discussions, mutate: refetchPosts } = useApi({
    endpoint: Api.getLecturerCoursesDiscussions,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semId!,
        },
        args: {
          id: params.courseId!,
        },
      }),
      [headers, params.courseId, semId]
    ),
    shouldFetch: semId != null && params.courseId != null,
  })

  return (
    <div className="w-full">
      {discussions != null && discussions.length === 0 ? (
        <NoContent
          header={t('error:posts_not_found')}
          subHeader={t('error:discussions_not_found_for_selected_course')}
          image={Warning}
          marginTop="5"
        />
      ) : (
        discussions?.map((post: CoursePost) => <Post key={post.id} post={post} refetchPosts={refetchPosts} />)
      )}
    </div>
  )
}
