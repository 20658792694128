export function GroupIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12">
      <g id="group_icon" transform="translate(-676.5 -376.342)">
        <rect
          id="Rectangle_4185"
          data-name="Rectangle 4185"
          width="13"
          height="12"
          transform="translate(676.5 376.342)"
          fill="rgba(0,0,0,0)"
        />
        <path
          id="Path_25063"
          data-name="Path 25063"
          d="M681,382.342a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,681,382.342Zm0-4a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,681,378.342Z"
          fill="gray"
        />
        <path
          id="Path_25064"
          data-name="Path 25064"
          d="M686.5,383.342a2,2,0,1,1,2-2A2,2,0,0,1,686.5,383.342Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,686.5,380.342Z"
          fill="gray"
        />
        <path
          id="Path_25065"
          data-name="Path 25065"
          d="M677.551,387.342a3.437,3.437,0,0,1-.051-.5,3.5,3.5,0,0,1,7,0,3.437,3.437,0,0,1-.051.5h1a4.333,4.333,0,0,0,.051-.5,4.5,4.5,0,0,0-9,0,4.333,4.333,0,0,0,.051.5Z"
          fill="gray"
        />
        <path
          id="Path_25066"
          data-name="Path 25066"
          d="M686,383.342a3.468,3.468,0,0,0-2.149.758l.648.755a2.493,2.493,0,0,1,3.95,2.487h1a3.437,3.437,0,0,0,.051-.5A3.5,3.5,0,0,0,686,383.342Z"
          fill="gray"
        />
      </g>
    </svg>
  )
}
