import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { useField } from 'formik'
import * as React from 'react'
import { CompleteIcon } from 'src/assets/icons/customIcons/Complete'
import { DownloadIcon } from 'src/assets/icons/customIcons/Download'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { formatFileSize, staticAssert, type Interchangeable } from 'src/helpers/fns'
import { useFileUpload, type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import * as Table from 'src/tailwind/components/Table'
import Swal from 'sweetalert2'
import { type UploadConfig } from '../../../../api'

import PopoverComponent from '../../PopoverComponent'

interface Props {
  readonly multiple?: boolean
  readonly config: UploadConfig
  readonly onChange: (files: readonly FileState[]) => void
  readonly fileText?: string
  readonly disabled?: boolean
  readonly required?: boolean
  readonly popover?: boolean
  readonly label?: string
  readonly name?: string
  readonly className?: string
  readonly instructions?: string
  readonly children?: JSX.Element
  readonly mediaFileType?: string
  readonly testId?: string
}

export default function FileInput({
  onChange,
  config,
  multiple,
  fileText,
  disabled,
  label,
  required,
  children,
  instructions,
  name = '',
  className = '',
  popover = true,
  mediaFileType = 'media-files',
  testId,
}: Props): React.ReactElement | null {
  const [, meta] = useField({
    name,
    validate(value: string | null) {
      if (required === true && value?.length === 0) {
        return label !== undefined ? `${label} ${t('common:is_required')}` : t('common:field_is_required')
      }
    },
  })

  const [files, { FILE_ADD, FILE_REMOVE }] = useFileUpload({
    allowed_extensions: config.allowed_extensions,
    max_chunk_size: config.max_chunk_size,
    max_upload_size: config.max_upload_size,
    url: config.url,
  })

  const t = useTranslatable()
  const ref = React.useRef<HTMLInputElement>(null)
  const locale = useLocale()
  const onChangeRef = React.useRef(onChange)
  onChangeRef.current = onChange

  React.useEffect(() => void onChangeRef.current(files), [files])

  async function handleDelete(index: number): Promise<void> {
    const alert = await Swal.fire({
      title: t('common:do_you_really_want_to_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#0D6EFD',
      cancelButtonColor: '#6C757D',
      confirmButtonText: t('common:confirm'),
      cancelButtonText: t('common:cancel'),
    })

    if (alert.isConfirmed) {
      FILE_REMOVE({ index })
    }
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.files != null) {
      if (multiple !== true) {
        FILE_REMOVE({ index: 0 })
      }

      Array.from(e.target.files).forEach((file) => {
        FILE_ADD(file)
      })
    }
  }

  const accept = config.allowed_extensions.map((ext) => '.' + ext).join(', ')

  return (
    <div className={`mb-3 flex flex-col flex-wrap ${className}`}>
      <label className="mb-[10px] flex flex-nowrap items-center font-bold text-primaryTextColor xxs:whitespace-normal">
        <span>
          {label ?? (multiple !== null ? t('file:attach_file_s') : t('file:attach_file_max_1_gb'))}
          {required != null && <span className="pl-1 text-errorMessages">*</span>}
        </span>
        {popover && (
          <PopoverComponent>
            <span className="flex flex-col">
              {instructions}
              <span>
                {t('file:allowed_extensions')}
                {': '}
                {accept}
              </span>
            </span>
          </PopoverComponent>
        )}
      </label>
      {(() => {
        if (multiple != null && multiple) {
          return (
            <>
              <label
                className={classnames(
                  'mr-2 flex h-[48px] w-[150px] cursor-pointer items-center justify-center rounded-[4px] bg-primaryBlue px-[20px] text-white hover:opacity-80 xxs:px-[10px]',
                  {
                    'cursor-not-allowed opacity-40 hover:!opacity-40': disabled,
                  }
                )}
              >
                <span>{fileText ?? t('file:choose_file')}</span>
                <input
                  className="hidden"
                  type="file"
                  ref={ref}
                  multiple={multiple}
                  accept={accept}
                  onChange={handleOnChange}
                  disabled={disabled}
                  data-testid={testId}
                />
              </label>
              <Table.Table className="mt-[24px]">
                {files.length > 0 && (
                  <Table.Thead className="border-b border-borderColor">
                    <Table.Tr>
                      <Table.Th>{t('file:file_name')}</Table.Th>
                      <Table.Th>{t('common:progress')}</Table.Th>
                      <Table.Th>{t('file:file_info')}</Table.Th>
                      <Table.Th />
                    </Table.Tr>
                  </Table.Thead>
                )}
                <Table.Tbody>
                  {files.map((fileState, index) => (
                    <Table.Tr className="border-b border-t-0 border-borderColor" key={index}>
                      {(() => {
                        if (fileState.remote.status === 'IN-PROGRESS') {
                          return (
                            <>
                              <Table.Td>{fileState.local.file.name}</Table.Td>
                              <Table.Td className="flex items-center justify-center">
                                <div className="mb-4 h-1.5 w-full rounded-full bg-borderColor">
                                  <div
                                    className="h-1.5 w-full rounded-full bg-primaryRed"
                                    style={{
                                      width: `${fileState.remote.progress}%`,
                                    }}
                                    role="progressbar"
                                    aria-valuenow={fileState.remote.progress}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </Table.Td>
                              <Table.Td>
                                {fileState.local.file.type} {formatFileSize(fileState.local.file.size)}
                              </Table.Td>
                              <Table.Td className="flex items-center justify-end">
                                <button
                                  onClick={() => {
                                    FILE_REMOVE({ index })
                                  }}
                                  data-testid={`${testId}-${index}-delete`}
                                >
                                  <div className="w-[24px]">
                                    <DeleteIcon />
                                  </div>
                                </button>
                              </Table.Td>
                            </>
                          )
                        } else if (fileState.remote.status === 'DONE') {
                          const file = fileState.remote.file!
                          return (
                            <>
                              <Table.Td>{file.originalName}</Table.Td>
                              <Table.Td className="flex items-center">
                                <CompleteIcon /> <span className="ml-2">{t('file:uploaded_successfully')}</span>
                              </Table.Td>
                              <Table.Td>
                                {file.mimeType} {formatFileSize(file.size)}
                              </Table.Td>
                              <Table.Td className="flex items-center justify-end">
                                <a
                                  href={`/${locale}/${mediaFileType}/${file.id}/download`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="mr-3 cursor-pointer rounded-full p-[3px] hover:bg-hover-icon"
                                  title={t('common:download')}
                                >
                                  <DownloadIcon />
                                </a>
                                <button
                                  onClick={() => void handleDelete(index)}
                                  type="button"
                                  className="cursor-pointer rounded-full p-[3px] hover:bg-hover-icon"
                                  title={t('common:delete')}
                                  data-testid={`${testId}-${index}-delete`}
                                >
                                  <div className="center flex size-[26px] items-center justify-center">
                                    <DeleteIcon />
                                  </div>
                                </button>
                              </Table.Td>
                            </>
                          )
                        } else if (fileState.remote.status === 'FAILED') {
                          return (
                            <>
                              <Table.Td>{fileState.local.file.name}</Table.Td>
                              <Table.Td className="text-primary-red">{fileState.remote.message}</Table.Td>
                              <Table.Td>
                                {fileState.local.file.type} {formatFileSize(fileState.local.file.size)}
                              </Table.Td>
                              <Table.Td className="flex items-center justify-end">
                                <button
                                  className="cursor-pointer rounded-full p-[3px] hover:bg-hover-icon"
                                  onClick={() => void handleDelete(index)}
                                  type="button"
                                  data-testid={`${testId}-${index}-delete`}
                                >
                                  <div className="w-[24px]">
                                    <DeleteIcon />
                                  </div>
                                </button>
                              </Table.Td>
                            </>
                          )
                        } else {
                          fileState.remote.status satisfies never
                          return null
                        }
                      })()}
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table.Table>
            </>
          )
        }
        return files.length === 0 ? (
          <div className="flex flex-wrap items-center sm:flex-nowrap">
            <label
              className={classnames(
                'mr-2 flex h-[48px] w-[200px] cursor-pointer items-center justify-center rounded-[4px] bg-primaryBlue px-[20px] text-white hover:opacity-80 xxs:px-[10px]',
                {
                  'cursor-not-allowed opacity-40 hover:!opacity-40': disabled,
                }
              )}
            >
              <span className="ml-1">{fileText ?? t('file:choose_file')}</span>
              <input
                className="hidden"
                type="file"
                ref={ref}
                multiple={multiple}
                accept={accept}
                onChange={handleOnChange}
                disabled={disabled}
                data-testid={testId}
              />
            </label>
            {children}
          </div>
        ) : (
          <>
            {files.map((fileState, index) => (
              <React.Fragment key={index}>
                {(() => {
                  if (fileState.remote.status === 'IN-PROGRESS') {
                    return (
                      <div className="flex w-full flex-wrap justify-between">
                        <div className="my-1 mr-1 flex flex-wrap items-center">
                          <div className="mr-1 whitespace-nowrap">{fileState.local.file.name.slice(0, 15)}...</div>
                          <div className="whitespace-nowrap">{formatFileSize(fileState.local.file.size)}</div>
                          <div className="w-full">
                            <div
                              className="h-2.5 rounded-full bg-primaryGreen p-0.5 text-center text-xs font-medium leading-none text-blue-100"
                              style={{
                                width: `${fileState.remote.progress}%`,
                              }}
                              role="progressbar"
                              aria-valuenow={fileState.remote.progress}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <Button variant="blue" type="button" disabled className="mr-1" title={t('common:download')}>
                            <DownloadIcon color="white" />
                          </Button>
                          <Button
                            variant="red"
                            type="button"
                            onClick={() => FILE_REMOVE({ index })}
                            disabled
                            title={t('common:delete')}
                            data-testid={`${testId}-${index}-delete`}
                          >
                            <FontAwesomeIcon icon={ProRegularSvgIcons.faTimes} />
                          </Button>
                        </div>
                      </div>
                    )
                  } else if (fileState.remote.status === 'DONE') {
                    const file = fileState.remote.file!

                    return (
                      <div className="flex w-full flex-wrap justify-between">
                        <div className="my-1 mr-1 flex flex-wrap items-center dark:text-white">
                          <div className="mr-1 whitespace-nowrap">{file.originalName.slice(0, 15)}...</div>
                          <div className="whitespace-nowrap">{formatFileSize(file.size)}</div>
                          <div className="w-full">
                            <div
                              className="h-2.5 rounded-full bg-primaryGreen p-0.5 text-center text-xs font-medium leading-none text-blue-100"
                              role="progressbar"
                              style={{ width: '100%' }}
                              aria-valuenow={100}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <a
                            href={`/${locale}/${mediaFileType}/${file.id}/download`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-1"
                          >
                            <Button type="button" variant="blue" title={t('common:download')}>
                              <DownloadIcon color="white" />
                            </Button>
                          </a>
                          <Button
                            variant="red"
                            onClick={() => void handleDelete(index)}
                            type="button"
                            title={t('common:delete')}
                            data-testid={`${testId}-${index}-delete`}
                          >
                            <DeleteIcon color="white" />
                          </Button>
                        </div>
                      </div>
                    )
                  } else if (fileState.remote.status === 'FAILED') {
                    return (
                      <div className="flex w-full flex-wrap justify-between">
                        <div className="my-1 mr-1 flex flex-wrap items-center">
                          <div className="mr-1 whitespace-nowrap">{fileState.local.file.name.slice(0, 15)}...</div>
                          <div className="whitespace-nowrap">{formatFileSize(fileState.local.file.size)}</div>
                          <div className="w-full">{fileState.remote.message}</div>
                        </div>
                        <div className="flex items-center">
                          <Button
                            type="button"
                            variant="blue"
                            className="mr-1 flex items-center"
                            disabled
                            title={t('common:download')}
                          >
                            <DownloadIcon color="white" />
                          </Button>
                          <Button
                            title={t('common:delete')}
                            variant="red"
                            className="ml-1 flex items-center"
                            type="button"
                            onClick={() => void handleDelete(index)}
                            style={{ zIndex: 0 }}
                            data-testid={`${testId}-${index}-delete`}
                          >
                            <DeleteIcon color="white" />
                          </Button>
                        </div>
                      </div>
                    )
                  } else {
                    staticAssert<Interchangeable<typeof fileState.remote.status, never>>()
                    return null
                  }
                })()}
              </React.Fragment>
            ))}
          </>
        )
      })()}
      {meta.error != null && meta.error.length > 0 && meta.touched && (
        <div className="mt-2 text-[13px] text-errorMessages">{meta.error}</div>
      )}
    </div>
  )
}
