import { useFormikContext } from 'formik'
import * as React from 'react'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import TextInput from 'src/views/components/forms/formik/TextInput'

export default function MessageForm({ children }: { children: React.ReactNode }): React.ReactElement {
  const formik = useFormikContext()
  const t = useTranslatable()

  return (
    <fieldset disabled={formik.isSubmitting}>
      <FormError />
      <div className="rounded-[12px] border border-borderGrey">
        <div className="flex items-center border-b border-borderGrey px-[24px] py-[11px]">
          <label className="mr-[12px] flex flex-nowrap font-bold text-primaryTextColor">
            {t('message:recipients')}:
          </label>
          <div className="flex flex-wrap text-primaryTextColor">{children}</div>
        </div>
        <div className="border-b border-borderGrey px-[24px] py-[11px]">
          <TextInput
            isHorizontal
            type="text"
            name="subject"
            className="!mb-0"
            placeholder={t('message:enter_the_title')}
            label={t('message:subject')}
            required
          />
        </div>
        <div className="mb-3 flex w-full px-[24px] py-[11px]">
          <TextareaInput
            label={t('message:message_text')}
            name="body"
            placeholder={t('message:enter_the_message_text')}
            rows={7}
            required
          />
        </div>
        <div className="mb-[16px] mt-4 flex w-full justify-end border-t border-borderGrey pr-[24px] pt-[11px]">
          <Button variant="red" disabled={!formik.isValid || !formik.dirty}>
            {t('message:send')}
          </Button>
        </div>
      </div>
    </fieldset>
  )
}
