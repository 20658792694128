import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'

import { useActiveSemester } from 'src/hooks/semesters'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function LecturerGroupsExportPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('common:export')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const activeSemester = useActiveSemester()
  const [searchParams] = useSearchParams()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id

  const { courseId } = useParams()

  const { data } = useApi({
    endpoint: Api.getLecturerGroupsExport,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          courseId: courseId!,
        },
        query: {
          semId: semId!,
        },
      }),
      [courseId, headers, semId]
    ),
    shouldFetch: semId != null && courseId != null,
  })

  React.useEffect(() => {
    if (data != null) {
      location.replace(data.downloadUrl)
    }
  }, [data])

  return <Loader className="m-auto flex" style={{ height: '100vh' }} />
}
