import { useFormikContext, type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { UploadConfigMap } from 'src/api/UploadConfig'
import { FeedbackIcon } from 'src/assets/icons/customIcons/menu-icons/Feedback'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FileInput from 'src/views/components/forms/formik/FileInput'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import Loader from 'src/views/components/Loader'

interface FormikValues {
  readonly subjectId: string
  readonly text: string
  readonly files: readonly FileState[]
}

export default function LecturerFeedbackAddPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('feedback:feedback')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const locale = useLocale()
  const activeSemester = useActiveSemester()?.id
  const [searchParams] = useSearchParams()
  const headers = useAuthenticatedHeaders()
  const semId = searchParams.get('semId') ?? activeSemester
  const t = useTranslatable()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)

  const initialValues: FormikValues = { subjectId: '', text: '', files: [] }

  const submit = React.useCallback(
    async ({ subjectId, text, files }: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> => {
      try {
        await Api.postLecturerFeedbacks({
          headers,
          body: {
            subjectId,
            text,
            files: files.map((e) => e.remote.file!.id),
          },
        })

        toast.success(t('feedback:add_feedback'))
        formikHelpers.resetForm()
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, t]
  )

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/feedbacks/?semId=${semId!}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  const breadcrumbsItems = [
    { page: `${t('feedback:feedback')}`, path: `/${locale}/lecturer/feedbacks` },
    { page: `${t('feedback:add_feedback')}`, path: pathname },
  ]

  return (
    <Form initialValues={initialValues} onSubmit={submit} isConfirmable={!successfullySubmited}>
      <Section title={t('feedback:add_feedback_message')} icon={<FeedbackIcon />} breadcrubms={breadcrumbsItems}>
        <FormContent />
      </Section>
    </Form>
  )
}

function FormContent(): React.ReactElement | null {
  const id = `label-${React.useId()}`
  const [fileInputId] = React.useState(() => `${id}-${Date.now()}`)
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const { data: subjects } = useApi({
    endpoint: Api.getLecturerFeedbacksSubjects,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  return (
    <div className="mt-6 w-full rounded-card bg-card px-6 py-9">
      <FormError />

      <SelectInput
        placeholder={t('common:choose')}
        label={t('message:subject')}
        name="subjectId"
        options={subjects.map((s) => ({ label: s.name, value: s.id })) ?? []}
        required
      />
      <TextareaInput
        name="text"
        className="mb-3"
        placeholder={t('statement:enter_the_statements_text')}
        label={t('common:text')}
        rows={7}
        required
      />
      <div className="my-4" style={{ zIndex: 0 }}>
        <FileInput
          key={fileInputId}
          multiple
          config={UploadConfigMap.lecturerFeedbacks}
          onChange={(files) => void formik.setFieldValue('files', files)}
        />
      </div>
      <div className="flex w-full justify-end">
        <Button type="submit" variant="red" disabled={!formik.isValid || !formik.dirty}>
          {t('message:send_message')}
        </Button>
      </div>
    </div>
  )
}
