interface Props {
  dataTestid?: string
}
export function CourseFailedIcon({ dataTestid = 'errorIcon' }: Props): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      style={{ minWidth: '12px' }}
      viewBox="0 0 12 12"
      data-testid={dataTestid}
    >
      <g id="course_failed_icon" transform="translate(-360 -235)">
        <circle
          id="Ellipse_400"
          data-name="Ellipse 400"
          cx="6"
          cy="6"
          r="6"
          transform="translate(360 235)"
          fill="#d83131"
        />
        <g id="Group_53" data-name="Group 53" transform="translate(277.612 -187.507) rotate(45)">
          <rect
            id="Rectangle_3697"
            data-name="Rectangle 3697"
            width="5"
            height="1"
            transform="translate(363 240)"
            fill="#fff"
          />
          <rect
            id="Rectangle_3698"
            data-name="Rectangle 3698"
            width="5"
            height="1"
            transform="translate(366 238) rotate(90)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}
