export default function PortfolioIcon(): JSX.Element {
  return (
    <svg width="18" height="18" viewBox="0 0 64 64" fill="#D6D6D6" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28 5.5C24.4 5.5 21.5 8.4 21.5 12C21.5 12.85 22.15 13.5 23 13.5C23.85 13.5 24.5 12.85 24.5 12C24.5 10.05 26.05 8.5 28 8.5H36C37.95 8.5 39.5 10.05 39.5 12C39.5 12.85 40.15 13.5 41 13.5C41.85 13.5 42.5 12.85 42.5 12C42.5 8.4 39.6 5.5 36 5.5H28ZM12 15.5C8.4 15.5 5.5 18.4 5.5 22V30.5C5.5 31.05 5.8 31.4998 6.25 31.7998C14.2 36.5498 23.1 38.9502 32 38.9502C40.9 38.9502 49.8 36.5498 57.75 31.7998C58.2 31.4998 58.5 31.05 58.5 30.5V22C58.5 18.4 55.6 15.5 52 15.5H12ZM12 18.5H52C53.95 18.5 55.5 20.05 55.5 22V29.6504C40.95 38.0504 23.05 38.0504 8.5 29.6504V22C8.5 20.05 10.05 18.5 12 18.5ZM7 36.5C6.15 36.5 5.5 37.15 5.5 38V52C5.5 55.6 8.4 58.5 12 58.5H52C55.6 58.5 58.5 55.6 58.5 52V38C58.5 37.15 57.85 36.5 57 36.5C56.15 36.5 55.5 37.15 55.5 38V52C55.5 53.95 53.95 55.5 52 55.5H12C10.05 55.5 8.5 53.95 8.5 52V38C8.5 37.15 7.85 36.5 7 36.5Z"
        fill="gray"
      />
    </svg>
  )
}
