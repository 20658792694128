import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { PdfIcon } from 'src/assets/icons/customIcons/Pdf'
import SyllabusIcon from 'src/assets/icons/customIcons/page-icons/Statements'
import { useAllSearchParams, useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import Warning from 'src/imgs/classroom_icon.svg'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Select from 'src/views/components/Select'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerSyllabusesPage(): JSX.Element | null {
  const t = useTranslatable()
  const { pathname } = useLocation()

  const breadcrumbsItems = [{ page: `${t('syllabus:syllabuses')}`, path: pathname }]

  return (
    <>
      <Helmet title={t('syllabus:syllabuses')} />
      <Section icon={<SyllabusIcon />} title={t('syllabus:syllabuses')} breadcrubms={breadcrumbsItems}>
        <SuspenseWrapper>
          <>
            <Semesters />
            <CoursesTable />
          </>
        </SuspenseWrapper>
      </Section>
    </>
  )
}

function Semesters(): JSX.Element {
  const headers = useAuthenticatedHeaders()
  const [searchParams, setSearchParams] = useSearchParams()
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const t = useTranslatable()
  const allSearchParams = useAllSearchParams()

  const { data: semesters } = useApi({
    endpoint: Api.getLecturerManageSemesters,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })

  const currentSemester = semesters.find((semester) => semester.id === semId)

  return (
    <Select
      className="w-full"
      value={semesters.filter((semester) => semester.id === currentSemester!.id)}
      options={semesters}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name + (option.isActive ? t('common:current') : '')}
      onChange={(option) => setSearchParams({ ...allSearchParams, semId: option!.id })}
    />
  )
}

function CoursesTable(): JSX.Element {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const [searchParams] = useSearchParams()
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id

  const { data: courses } = useApi({
    endpoint: Api.getLecturerCoursesManage,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semId!,
        },
      }),
      [headers, semId]
    ),
    shouldFetch: semId != null,
  })
  if (courses == null) return <Loader className="m-auto flex" />

  const degrees = [...new Set(courses.map((course) => course.degree))]

  return courses.length === 0 ? (
    <NoContent
      image={Warning}
      header={t('error:items_not_found')}
      subHeader={t('error:try_adjusting_your_search_or_change_the_semester')}
      marginTop="5"
    />
  ) : (
    <>
      {degrees.map((degree) => (
        <div key={degree}>
          <h3 className="mt-4 font-semibold">{t(`degrees:${degree}`)}</h3>
          <Table.Table className="mt-3">
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{t('common:unique')}</Table.Th>
                <Table.Th>{t('course:course_name')}</Table.Th>
                <Table.Th>{t('course:credit')}</Table.Th>
                <Table.Th>{t('common:export')}</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {courses
                .filter((course) => course.degree === degree)
                .map((course) => (
                  <Table.Tr key={course.id}>
                    <Table.Td>{course.code}</Table.Td>
                    <Table.Td className="max-w-[170px] text-primaryBlueLink dark:text-primaryTextColor">
                      <Link to={`/${locale}/lecturer/courses/${course.id}/syllabus?semId=${semId!}`}>
                        {course.name}
                      </Link>
                    </Table.Td>
                    <Table.Td>{course.credits}</Table.Td>
                    <Table.Td>
                      {Boolean(course.syllabus?.syllabusFile?.id) && (
                        <a
                          href={`/${locale}/media-files/${course.syllabus?.syllabusFile?.id}/download`}
                          target="_blank"
                          rel="noreferrer"
                          className="hover:bg-hover-icon"
                        >
                          <PdfIcon width="24" height="24" />
                        </a>
                      )}
                    </Table.Td>
                  </Table.Tr>
                ))}
            </Table.Tbody>
          </Table.Table>
        </div>
      ))}
    </>
  )
}
