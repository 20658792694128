import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import * as Api from 'src/api'
import { LibraryLargeIcon } from 'src/assets/icons/customIcons/LibraryLarge'
import { NewsIcon } from 'src/assets/icons/customIcons/News'
import { RedArrowIcon } from 'src/assets/icons/customIcons/RedArrow'
import { ScheduleLargeIcon } from 'src/assets/icons/customIcons/ScheduleLargeIcon'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useUserState, useUserStateMutateOnMount } from 'src/hooks/userState'

import InfoCard from 'src/tailwind/components/InfoCard'
import { News } from 'src/tailwind/components/News'
import { ScheduleList } from 'src/tailwind/components/ScheduleList'
import { Section } from 'src/tailwind/components/Section'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'

export default function LecturerHomePage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <>
      <Helmet title={t('system:home_page')} />
      <PageContent />
    </>
  )
}

function PageContent(): JSX.Element {
  const t = useTranslatable()
  const userState = useUserState()
  const locale = useLocale()

  return (
    <div>
      <Section homePage>
        <InfoCard
          title={t('library:quantity')}
          to={`/${locale}/library`}
          icon={<LibraryLargeIcon />}
          quantity={userState.data != null ? userState.data.libraryBalance : ''}
          className="w-full max-w-[450px]"
        />
      </Section>
      <Section
        homePage
        title={t('news:news')}
        icon={<NewsIcon />}
        rightElement={
          <Link
            to={`/${locale}/news`}
            className="group relative flex cursor-pointer items-center rounded-card py-[7px] pl-4 pr-7 hover:bg-hover-card"
          >
            <span className="mr-[7px] text-primaryTextColor">{t('news:all_news')}</span>
            <div className="absolute right-[13px] duration-500 ease-in-out group-hover:mr-[-5px]">
              <RedArrowIcon />
            </div>
          </Link>
        }
      >
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <ErrorBoundary errorElement={<ErrorElement />}>
            <NewsOld />
          </ErrorBoundary>
        </React.Suspense>
      </Section>
      <Section homePage title={t('schedule:today_schedule')} icon={<ScheduleLargeIcon />}>
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <ErrorBoundary errorElement={<ErrorElement />}>
            <Schedule />
          </ErrorBoundary>
        </React.Suspense>
      </Section>
    </div>
  )
}

function NewsOld(): React.ReactElement {
  const locale = useLocale()
  const dateLocale = useDateLocale()
  const headers = useAuthenticatedHeaders()
  const {
    data: { data: news },
  } = useApi({
    endpoint: Api.getNews,
    params: React.useMemo(() => ({ headers, query: { perPage: 2, page: 1 } }), [headers]),
  })

  return (
    <ul className="mb-0 flex gap-3 pl-0 xxs:flex-col xs:flex-col">
      {news.map((n) => {
        return (
          <News
            to={`/${locale}/news/${n.id}`}
            key={n.id}
            title={n.title}
            date={format(parseISO(n.createdAt), 'd MMMM yyyy, HH:mm', {
              locale: dateLocale,
            })}
            className="w-full"
          />
        )
      })}
    </ul>
  )
}

function Schedule(): React.ReactElement | null {
  const t = useTranslatable()
  const locale = useLocale()
  const userState = useUserState()
  const headers = useAuthenticatedHeaders()
  const { data } = useApi({
    endpoint: Api.getLecturerSchedulesCurrent,
    params: React.useMemo(() => ({ headers }), [headers]),
  })
  const schedule = data

  if (userState.data == null) return null
  const today = new Date().getDay()
  const todaySchedule = schedule.filter((schedule) => schedule.number === today)

  return (
    <div className="overflow-x-scroll">
      {todaySchedule != null && todaySchedule.length > 0 ? (
        <ul className="inline-block w-full min-w-[768px] pl-0">
          {todaySchedule[0]?.schedules.map((schedule) => (
            <ScheduleList
              key={schedule.id}
              to={`/${locale}/lecturer/courses/${schedule.course.id}/syllabus`}
              time={schedule.hour.times}
              classroom={schedule.locationName ?? ''}
              lecture={schedule.group.lecturers}
              group={schedule.group.name}
              online={schedule.lectureTypeName}
              course={schedule.course.fullName}
            />
          ))}
        </ul>
      ) : (
        <div className="border-0 text-center text-primaryTextColor" style={{ borderRadius: '8px' }} role="alert">
          {t('schedule:today_your_schedule_is_empty')}
        </div>
      )}
    </div>
  )
}
