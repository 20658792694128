import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type FormikHelpers } from 'formik'
import * as React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { EmailIcon } from 'src/assets/icons/customIcons/Email'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import * as Table from 'src/tailwind/components/Table'
import NoContent from 'src/views/components/NoContent'
import { Form } from 'src/views/components/forms/formik/Form'
import MessageForm from './MessageForm'
import ScoresForm from './ScoresForm'

interface Props {
  readonly teachingGroups: Api.getLecturerTeachingGroupsOk
  readonly students: readonly Api.LecturerUser[]
  readonly groupsPaper: Api.Paper | null
}
interface MessageValues {
  readonly subject: string
  readonly body: string
}
const messageValues = { subject: '', body: '' }

export default function StudentsTable({ students, teachingGroups, groupsPaper }: Props): React.ReactElement | null {
  const [finalScore, setFinalScore] = React.useState<any>({})
  const [show, setShow] = React.useState(false)
  const [recipients, setRecipients] = React.useState<string[]>([])
  const t = useTranslatable()
  const locale = useLocale()
  const [searchParams] = useSearchParams()
  const groupId = searchParams.get('groupId')
  const semId = searchParams.get('semId')
  const criterions = students[0]?.group?.paper?.criterions ?? groupsPaper?.criterions
  const headers = useAuthenticatedHeaders()
  const { courseId } = useParams()
  const theme = useTheme()
  const formikSubmit = React.useCallback(
    async (values: MessageValues, helpers: FormikHelpers<MessageValues>): Promise<void> => {
      const { subject, body } = values
      try {
        await Api.postMessagesToStudents({
          headers,
          args: {
            courseId: courseId!,
          },
          body: {
            students: recipients,
            subject,
            body,
          },
          query: { semId: semId! },
        })
        toast.success(t('uncategorized:message_successfully_sent'))
        setShow(false)
        helpers.resetForm()
      } catch (error) {
        console.error(error)
      }
    },
    [headers, courseId, recipients, semId, t]
  )

  const groupName = students[0]?.group?.name ?? teachingGroups?.find((group) => group.id === groupId)?.name
  if (students.length === 0) {
    return <NoContent header={t('common:records_not_found')} image={Warning} marginTop="5" />
  }

  return (
    <>
      <Table.Table className="mt-3">
        <Table.Thead>
          <Table.Tr>
            <Table.Th colSpan={10} className="pb-0">
              {' '}
              <h3>{groupName}</h3>
            </Table.Th>
          </Table.Tr>
          <Table.Tr>
            <Table.Th style={{ width: '1%' }}>#</Table.Th>
            <Table.Th style={{ width: '1%' }}>
              <input
                type="checkbox"
                onChange={({ target }) => {
                  if (target.checked) {
                    setRecipients(students.map((student) => student.id))
                  } else {
                    setRecipients([])
                  }
                }}
              />
            </Table.Th>
            <Table.Th scope="col" className="pl-1 text-start">
              {t('uncategorized:name_surname')}
            </Table.Th>
            {criterions != null &&
              (students[0]?.group?.paper != null || groupsPaper != null) &&
              criterions.map((criteria) => (
                <Table.Th scope="col" className="!text-center" style={{ fontSize: '14px' }} key={criteria.id}>
                  <div className="flex flex-col" title={criteria.fullName}>
                    <span>{criteria.acr}</span> <small style={{ fontSize: '12px' }}>(max-{criteria.max})</small>
                  </div>
                </Table.Th>
              ))}
            <Table.Th scope="col" className="!text-center">
              {t('common:total')}
            </Table.Th>
            <Table.Th scope="col" className="!text-center">
              {t('course:cred')}
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {students.map((student, i) => (
            <Table.Tr key={student.id}>
              <Table.Td>{i + 1}</Table.Td>
              <Table.Td className="align-middle">
                <input
                  type="checkbox"
                  checked={recipients.includes(student.id)}
                  onChange={() => {
                    if (recipients.includes(student.id)) {
                      setRecipients(recipients.filter((id) => id !== student.id))
                    } else {
                      setRecipients([...recipients, student.id])
                    }
                  }}
                />
              </Table.Td>
              <Table.Td className="pl-0 pr-4">
                <div className="flex items-center px-0 pl-1 no-underline">
                  {student.photoUrl != null ? (
                    <a href={student.photoUrl} className="mr-2 size-[40px] overflow-hidden rounded-full">
                      {' '}
                      <img src={student.photoUrl} alt="studentAvatar" />
                    </a>
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: student.avatar }} className="mr-2" />
                  )}
                  <div className="flex flex-col" style={{ fontSize: '14px' }}>
                    <Link
                      to={`/${locale}/users/${student.uid}`}
                      className="w-min whitespace-nowrap text-primaryBlueLink hover:underline dark:text-primaryTextColor"
                      data-tooltip-id={`${student.id}-student`}
                    >
                      {student.fullName}
                    </Link>
                    <Tooltip
                      id={`${student.id}-student`}
                      place="top"
                      variant={theme === 'dark' ? 'dark' : 'light'}
                      className="m-0 p-1"
                    >
                      <p className="mb-0">{t('course:students_page')}</p>
                    </Tooltip>
                    <span className="whitespace-nowrap">
                      ({t('person:e_mail')}: <span style={{ fontSize: '12px' }}>{student.email}</span>)
                    </span>
                  </div>
                </div>
              </Table.Td>
              {criterions != null &&
                (student.group?.paper != null || groupsPaper != null) &&
                criterions.map((criteria) => (
                  <Table.Td key={criteria.id}>
                    <div style={{ width: '70px', margin: 'auto' }}>
                      <ScoresForm
                        student={student}
                        criteria={criteria}
                        setFinalScore={setFinalScore}
                        groupsPaper={groupsPaper}
                      />
                    </div>
                  </Table.Td>
                ))}
              <Table.Td className="text-center">
                {finalScore?.[student.profileId]?.finalScore ?? student.finalScore?.finalScore.toFixed(2)}
              </Table.Td>
              <Table.Td className="text-center">
                {finalScore?.[student.profileId]?.credits ?? student.finalScore?.credits}
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table.Table>
      <div className="my-3 flex w-full justify-end">
        <Button variant="blue" type="button" disabled={recipients.length < 1} onClick={() => setShow(true)}>
          {t('message:send_message')}
        </Button>
      </div>
      <Modal
        title={t('message:messages_compose')}
        icon={<EmailIcon />}
        isModalOpen={show}
        onClose={() => setShow(false)}
      >
        <Form
          initialValues={messageValues}
          onSubmit={formikSubmit}
          classNames={{
            form: 'overflow-auto py-4',
          }}
        >
          <MessageForm>
            {students
              ?.filter((student) => recipients.includes(student.id))
              .map((student, key) => (
                <div className="mr-2" style={{ width: 'fit-content' }} key={student.id}>
                  <div className="flex">
                    {student.photoUrl != null && student.photoUrl.length > 0 ? (
                      <img
                        src={student.photoUrl}
                        style={{
                          borderRadius: '100%',
                          height: 24,
                          width: 24,
                          objectFit: 'contain',
                          background: 'white',
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon icon={ProRegularSvgIcons.faUser} />
                    )}{' '}
                    <Link to={`/${locale}/users/${student.uid}`} className="no-underline">
                      {student.fullName}
                    </Link>
                    {key === recipients.length - 1 ? '' : ','}
                  </div>
                </div>
              ))}
          </MessageForm>
        </Form>
      </Modal>
    </>
  )
}
