import EventEmitter from 'eventemitter3'
import React, { useMemo, useState } from 'react'
import type * as Api from 'src/api'
import { postMediaFilesUpload } from 'src/api/mediaFileUpload'
import { identity } from 'src/helpers/fns'
import { useAnyHeaders } from './auth/app'

export type FileUploadStatus = 'IN-PROGRESS' | 'FAILED' | 'DONE'

export interface FileState {
  readonly local: {
    readonly file: File
  }
  readonly remote: {
    readonly status: FileUploadStatus
    readonly progress: number
    readonly message?: string
    readonly file?: Api.MediaFile
  }
}

export interface Actions {
  readonly FILE_ADD: (payload: File) => void
  readonly FILE_REMOVE: (payload: { readonly index: number }) => void
}

export function useFileUpload(config: Api.UploadConfig): [readonly FileState[], Actions] {
  const [state, setState] = useState<readonly FileState[]>([])
  const stateRef = React.useRef(state)
  stateRef.current = state

  const events = useMemo(() => new EventEmitter<Actions>(), [])

  const headers = useAnyHeaders()
  const headersRef = React.useRef(headers)
  headersRef.current = headers

  const FILE_REMOVE = React.useCallback(
    ({ index }: { readonly index: number }) => {
      events.emit('FILE_REMOVE', { index })

      setState((state) => {
        return [...state.slice(0, index), ...state.slice(index + 1)]
      })
    },
    [events]
  )

  const FILE_UPLOAD_DONE = React.useCallback(
    ({ index, file }: { readonly index: number; readonly file: Api.MediaFile }) => {
      setState((state) => {
        return [
          ...state.slice(0, index),
          {
            ...state[index]!,
            remote: {
              ...state[index]!.remote,
              status: 'DONE',
              file,
            },
          },
          ...state.slice(index + 1),
        ]
      })
    },
    []
  )
  const FILE_UPLOAD_FAILED = React.useCallback(
    ({ index, message }: { readonly index: number; readonly message: string }) => {
      setState((state) => {
        return [
          ...state.slice(0, index),
          {
            ...state[index]!,
            remote: {
              ...state[index]!.remote,
              status: 'FAILED',
              message,
            },
          },
          ...state.slice(index + 1),
        ]
      })
    },
    []
  )
  const FILE_UPLOAD_PROGRESS = React.useCallback(
    ({ index, progress }: { readonly index: number; readonly progress: number }) => {
      setState((state) => {
        return [
          ...state.slice(0, index),
          {
            ...state[index]!,
            remote: {
              ...state[index]!.remote,
              progress,
            },
          },
          ...state.slice(index + 1),
        ]
      })
    },
    []
  )
  const FILE_UPLOAD_START = React.useCallback(
    ({ index }: { readonly index: number }) => {
      async function uploadFile(index: number): Promise<void> {
        const state = stateRef.current
        const fileState = state[index]!
        let canceled = false

        let handleFileRemove: Actions['FILE_REMOVE'] | null = null

        try {
          const upload = await postMediaFilesUpload({
            config,
            headers: headersRef.current,
            file: fileState.local.file,
          })

          handleFileRemove = (payload) => {
            if (payload.index === index) {
              canceled = true
              upload.cancel()

              if (handleFileRemove != null) {
                events.removeListener('FILE_REMOVE', handleFileRemove)
              }
            }
          }

          events.addListener('FILE_REMOVE', handleFileRemove)

          upload.progress.eventEmitter.addListener('update', (progress) => {
            if (!canceled) {
              FILE_UPLOAD_PROGRESS({ index, progress })
            }
          })

          const file = await upload.result

          if (!canceled) {
            FILE_UPLOAD_DONE({ index, file })
          }
        } catch (_err: any) {
          console.error(_err)

          if (!canceled) {
            const err = identity<Api.AuthError | Api.ErrorMessage | Api.ErrorsObject>(_err)
            let message = 'File Upload Failed!'

            if (['AuthError', 'ErrorMessage', 'ErrorsObject'].includes(err.type)) {
              if (err.type === 'ErrorsObject') {
                message = err.errors.file?.[0] ?? message
              } else {
                message = err.message
              }
            }

            FILE_UPLOAD_FAILED({
              index,
              message,
            })
          }
        } finally {
          if (handleFileRemove != null) {
            events.removeListener('FILE_REMOVE', handleFileRemove)
          }
        }
      }

      setState((state) => {
        return [
          ...state.slice(0, index),
          {
            ...state[index]!,
            remote: {
              status: 'IN-PROGRESS',
              progress: 0,
              message: void null,
              file: void null,
            },
          },
          ...state.slice(index + 1),
        ]
      })

      setTimeout(() => void uploadFile(index))
    },
    [FILE_UPLOAD_DONE, FILE_UPLOAD_FAILED, FILE_UPLOAD_PROGRESS, config, events]
  )

  const FILE_ADD = React.useCallback(
    (payload: File) => {
      setState((state) => {
        const fileState: FileState = {
          local: {
            file: payload,
          },
          remote: {
            progress: 0,
            status: 'IN-PROGRESS',
          },
        }
        const newFileIndex = state.length

        setTimeout(() => {
          FILE_UPLOAD_START({ index: newFileIndex })
        })

        return [...state, fileState]
      })
    },
    [FILE_UPLOAD_START]
  )

  return [state, { FILE_ADD, FILE_REMOVE }]
}
