export function RecievedIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="recieved_icon" transform="translate(-360 -235)">
        <circle
          id="Ellipse_400"
          data-name="Ellipse 400"
          cx="6"
          cy="6"
          r="6"
          transform="translate(360 235)"
          fill="#519ed9"
        />
        <path
          id="Path_98"
          data-name="Path 98"
          d="M368.27,238.278l-3.314,4.007-2.223-1.681"
          transform="translate(0.547 0.656)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
