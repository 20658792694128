import { useFormikContext, type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

import { useActiveSemester } from 'src/hooks/semesters'
import { Button } from 'src/tailwind/components/Button'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import Loader from 'src/views/components/Loader'

interface Option {
  readonly value: string
  readonly label: string
}

interface FormikValues {
  readonly visibility: string
  readonly commenting: string
  readonly body: string
  readonly groups: readonly Option[]
}
export default function LectureCoursesDiscussionsAddPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('discussion:discussion')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const headers = useAuthenticatedHeaders()
  const t = useTranslatable()
  const { courseId } = useParams()
  const activeSemester = useActiveSemester()
  const [searchParams] = useSearchParams()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const navigate = useNavigate()
  const locale = useLocale()

  const submit = React.useCallback(
    async ({ visibility, commenting, body, groups }: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => {
      try {
        await Api.postLecturerCoursesDiscussions({
          headers,
          body: {
            semId: +semId!,
            visibility: +visibility,
            commenting: +commenting,
            body,
            groups: +visibility === 2 ? groups.map((g) => g.value) : undefined,
          },
          args: {
            id: courseId!,
          },
        })

        formikHelpers.resetForm()

        toast.success(t('discussion:post_successfully_added'))
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [courseId, headers, semId, t]
  )
  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/courses/${courseId!}/discussions/?semId=${semId!}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])
  return (
    <Form<FormikValues>
      initialValues={{ visibility: '1', commenting: '1', body: '', groups: [] }}
      onSubmit={submit}
      classNames={{ form: 'w-full' }}
      isConfirmable
    >
      <FormContent />
    </Form>
  )
}
function FormContent(): JSX.Element {
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const { courseId } = useParams()
  const activeSemester = useActiveSemester()
  const [searchParams] = useSearchParams()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const locale = useLocale()

  const postsPerm = [
    { value: '1', label: t('group:all_of_your_groups') },
    { value: '2', label: t('group:only_selected_groups') },
  ]
  const commenting = [
    { value: '1', label: t('discussion:switched_on') },
    { value: '2', label: t('discussion:switched_off') },
  ]

  return (
    <div className="mt-6 w-full rounded-card bg-card px-6 py-9">
      <h3 className="mb-4 font-bold">{t('group:add_post')}</h3>
      <FormError />
      <SelectInput
        name="visibility"
        label={t('discussion:who_can_see_the_post')}
        placeholder={t('common:choose')}
        required
        options={postsPerm}
      />
      {formik.values.visibility === '2' && <SelectGroup />}
      <SelectInput
        name="commenting"
        label={t('discussion:comments')}
        placeholder={t('discussion:comments')}
        required
        options={commenting}
      />
      <TextareaInput name="body" label={t('common:text')} rows={5} required />
      <div className="mt-4 flex flex-wrap justify-end">
        <BackButton link={`/${locale}/lecturer/courses/${courseId!}/discussions?semId=${semId!}`} />
        <Button type="submit" variant="red" className="ml-2" disabled={!formik.isValid || !formik.dirty}>
          {t('discussion:post')}
        </Button>
      </div>
    </div>
  )
}

function SelectGroup(): JSX.Element {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const activeSemester = useActiveSemester()
  const [searchParams] = useSearchParams()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const params = useParams()

  const { data: groups } = useApi({
    endpoint: Api.getLecturerTeachingGroups,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semId!,
        },
        args: {
          id: params.courseId!,
        },
      }),
      [headers, params.courseId, semId]
    ),
    shouldFetch: semId != null && params.courseId != null,
  })

  return (
    <React.Suspense fallback={<Loader className="m-auto flex" />}>
      <ErrorBoundary errorElement={<ErrorElement />}>
        <SelectInput
          name="groups"
          label={t('group:groups')}
          placeholder={t('group:select_group')}
          required
          options={groups?.map((g) => ({ value: g.id, label: g.name })) ?? []}
          isMulti
        />
      </ErrorBoundary>
    </React.Suspense>
  )
}
