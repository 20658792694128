import { useFormikContext } from 'formik'
import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'

import { Form } from 'src/views/components/forms/formik/Form'
import ScoreField from 'src/views/components/forms/ScoreField'

interface ScoresFormProps {
  readonly student: Api.LecturerUser
  readonly criteria: Api.PaperCriteria
  readonly setFinalScore: React.Dispatch<React.SetStateAction<{ [key: string]: unknown }>>
  readonly groupsPaper: Api.Paper | null
}

export default function ScoresForm({
  student,
  setFinalScore,
  criteria,
  groupsPaper,
}: ScoresFormProps): React.ReactElement {
  const score = student.scores?.find((score) => score.criteriaId.toString() === criteria.id)?.score
  const [submitedScore, setSubmitedScore] = React.useState(score)
  const headers = useAuthenticatedHeaders()
  const [searchParams] = useSearchParams()
  const { courseId } = useParams()
  const [submitedSuccessfully, setSubmitedSuccessfully] = React.useState(false)

  const groupId = searchParams.get('groupId')

  async function onSubmit(values: { score: number | null | undefined }): Promise<void> {
    setSubmitedScore(values.score)

    const result = await Api.postStudentsScore({
      headers,
      args: {
        courseId: courseId!,
        groupId: student.group?.id ?? groupId!,
        profileId: student.profileId.toString(),
      },
      body: {
        criteriaId: criteria.id,
        score: values.score!,
      },
    })
    setFinalScore((prev) => ({ ...prev, [student.profileId.toString()]: result }))
    setSubmitedSuccessfully(true)
  }

  return (
    <Form onSubmit={onSubmit} initialValues={{ score }} isConfirmable={!submitedSuccessfully}>
      <FormContent criteria={criteria} student={student} submitedScore={submitedScore} groupsPaper={groupsPaper} />
    </Form>
  )
}
interface FormContentProps {
  readonly criteria: Api.PaperCriteria
  readonly student: Api.LecturerUser
  readonly submitedScore: number | null | undefined
  readonly groupsPaper: Api.Paper | null
}

function FormContent({ criteria, student, submitedScore, groupsPaper }: FormContentProps): React.ReactElement {
  const formik = useFormikContext<{ score: string }>()
  const disabled = student.group?.paper?.active === false || groupsPaper?.active === false || !criteria.canEdit

  return (
    <ScoreField
      name="score"
      type="number"
      loading={formik.isSubmitting}
      disabled={disabled}
      submit={formik.handleSubmit}
      submitedScore={submitedScore}
    />
  )
}
