export default function FeedbackIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24.001"
      viewBox="0 0 24 24.001"
      data-testid="feedbackIcon"
    >
      <g id="feedback_icon" transform="translate(-305 -414.999)" className="fill-primaryWhite stroke-svgColor">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g id="Union_35" data-name="Union 35" transform="translate(305 1003)" fill="none">
          <path d="M9-567H4a4,4,0,0,1-4-4v-13a4,4,0,0,1,4-4H20a4,4,0,0,1,4,4v13a4,4,0,0,1-4,4H15l-3,3Z" stroke="none" />
          <path
            d="M 11.99978542327881 -566.827880859375 L 14.17187023162842 -569 L 19.99979972839355 -569 C 21.10288047790527 -569 22.00030136108398 -569.8970336914062 22.00030136108398 -570.9996337890625 L 22.00030136108398 -584.0001220703125 C 22.00030136108398 -585.1032104492188 21.10288047790527 -586.0006103515625 19.99979972839355 -586.0006103515625 L 3.999600648880005 -586.0006103515625 C 2.897020578384399 -586.0006103515625 2.000000715255737 -585.1032104492188 2.000000715255737 -584.0001220703125 L 2.000000715255737 -570.9996337890625 C 2.000000715255737 -569.8970336914062 2.897020578384399 -569 3.999600648880005 -569 L 9.827960968017578 -569 L 11.99978542327881 -566.827880859375 M 11.99970054626465 -563.9993896484375 L 8.999460220336914 -567 L 3.999600648880005 -567 C 1.791000723838806 -567 6.805419729971618e-07 -568.791015625 6.805419729971618e-07 -570.9996337890625 L 6.805419729971618e-07 -584.0001220703125 C 6.805419729971618e-07 -586.2095947265625 1.791000723838806 -588.0006103515625 3.999600648880005 -588.0006103515625 L 19.99979972839355 -588.0006103515625 C 22.20930099487305 -588.0006103515625 24.00030136108398 -586.2095947265625 24.00030136108398 -584.0001220703125 L 24.00030136108398 -570.9996337890625 C 24.00030136108398 -568.791015625 22.20930099487305 -567 19.99979972839355 -567 L 15.00030040740967 -567 L 11.99970054626465 -563.9993896484375 Z"
            stroke="none"
            className="fill-svgColor"
          />
        </g>
        <rect
          id="Rectangle_4293"
          data-name="Rectangle 4293"
          width="14"
          height="2"
          rx="1"
          className="fill-svgColor"
          transform="translate(310 420)"
        />
        <rect
          id="Rectangle_4294"
          data-name="Rectangle 4294"
          width="14"
          height="2"
          rx="1"
          className="fill-svgColor"
          transform="translate(310 424.5)"
        />
        <rect
          id="Rectangle_4295"
          data-name="Rectangle 4295"
          width="7"
          height="2"
          rx="1"
          className="fill-svgColor"
          transform="translate(310 429)"
        />
      </g>
    </svg>
  )
}
