export function InfoIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="info_icon" transform="translate(-1247 -205)">
        <g
          id="Ellipse_391"
          data-name="Ellipse 391"
          transform="translate(1247 205)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <circle cx="6" cy="6" r="6" stroke="none" />
          <circle cx="6" cy="6" r="5.5" fill="none" />
        </g>
        <circle
          id="Ellipse_392"
          data-name="Ellipse 392"
          cx="1"
          cy="1"
          r="1"
          transform="translate(1252 207.5)"
          fill="gray"
        />
        <rect
          id="Rectangle_3660"
          data-name="Rectangle 3660"
          width="2"
          height="1"
          transform="translate(1252 210)"
          fill="gray"
        />
        <rect
          id="Rectangle_3661"
          data-name="Rectangle 3661"
          width="1"
          height="4"
          transform="translate(1253 210)"
          fill="gray"
        />
        <rect
          id="Rectangle_3662"
          data-name="Rectangle 3662"
          width="3"
          height="1"
          transform="translate(1252 213)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
