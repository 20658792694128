export function LibraryLargeIcon(): JSX.Element {
  return (
    <svg id="library_large_icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
      <circle id="Ellipse_371" data-name="Ellipse 371" cx="24" cy="24" r="24" fill="#85bddb" />
      <g id="Group_30" data-name="Group 30" transform="translate(-2.28 -5)">
        <g id="Group_74" data-name="Group 74" transform="translate(-20)">
          <g
            id="Rectangle_3709"
            data-name="Rectangle 3709"
            transform="translate(45.28 21)"
            fill="#85bddb"
            stroke="#fff"
            strokeWidth="2"
          >
            <rect width="14" height="20" rx="1" stroke="none" />
            <rect x="1" y="1" width="12" height="18" fill="none" />
          </g>
          <g
            id="Rectangle_3710"
            data-name="Rectangle 3710"
            transform="translate(33.28 21)"
            fill="#85bddb"
            stroke="#fff"
            strokeWidth="2"
          >
            <rect width="14" height="20" rx="1" stroke="none" />
            <rect x="1" y="1" width="12" height="18" fill="none" />
          </g>
          <g id="Path_22449" data-name="Path 22449" transform="translate(45.28 21)" fill="#85bddb">
            <path
              d="M 1 18.98708534240723 L 1 1.462196111679077 C 1 1.337566137313843 1.148659944534302 1.074486136436462 1.467740058898926 0.9056360721588135 C 1.967096447944641 0.6413704752922058 8.462949752807617 -2.242733240127563 10 -2.920706272125244 L 10 14.76617622375488 C 10 14.85482597351074 10 15.00319576263428 9.572580337524414 15.20526599884033 C 9.224499702453613 15.36983585357666 7.043970108032227 16.32631683349609 5.120130062103271 17.17019653320312 C 2.853648900985718 18.16437911987305 1.404691934585571 18.80063438415527 1 18.98708534240723 Z"
              stroke="none"
            />
            <path
              d="M 9 -1.384281158447266 C 6.671547412872314 -0.351226806640625 2.705558776855469 1.415632247924805 2 1.757635116577148 L 2 17.44827651977539 C 2.694117546081543 17.14254570007324 3.608688831329346 16.7412052154541 4.718430042266846 16.25442695617676 C 6.452438354492188 15.49381637573242 8.395650863647461 14.64143657684326 9 14.36787986755371 L 9 -1.384281158447266 M 10.09973049163818 -3.977659225463867 C 10.62815093994141 -3.977659225463867 11 -3.450647354125977 11 -2.994653701782227 L 11 14.76617622375488 C 11 15.32147598266602 10.70781993865967 15.77468585968018 10 16.10932540893555 C 9.292180061340332 16.4439754486084 1.957819938659668 19.63790512084961 1.364069938659668 19.92069625854492 C 0.770319938659668 20.20347595214844 0 19.68105506896973 0 19.12574577331543 L 0 1.462196350097656 C 0 0.906886100769043 0.40625 0.3359756469726562 1 0.02176570892333984 C 1.59375 -0.2924442291259766 9.020319938659668 -3.582233428955078 9.734370231628418 -3.896444320678711 C 9.862067222595215 -3.952634811401367 9.984651565551758 -3.977659225463867 10.09973049163818 -3.977659225463867 Z"
              stroke="none"
              fill="#fff"
            />
          </g>
          <g id="Path_22450" data-name="Path 22450" transform="translate(36.28 21)" fill="#85bddb">
            <path
              d="M 10 18.98708724975586 C 9.595308303833008 18.80063629150391 8.146350860595703 18.16438102722168 5.879869937896729 17.17019844055176 C 3.956029891967773 16.32631874084473 1.775500059127808 15.36983871459961 1.427430033683777 15.20527935028076 C 1 15.00319862365723 1 14.85482883453369 1 14.76617908477783 L 1 -2.920600652694702 C 2.546836853027344 -2.238389730453491 9.033513069152832 0.6416990756988525 9.532259941101074 0.9056389331817627 C 9.851340293884277 1.074488997459412 10 1.337568998336792 10 1.462198972702026 L 10 18.98708724975586 Z"
              stroke="none"
            />
            <path
              d="M 2 -1.384284973144531 L 2 14.36788272857666 C 2.604334831237793 14.641432762146 4.547561645507812 15.49381923675537 6.281569957733154 16.25442886352539 C 7.391310691833496 16.74120712280273 8.305887222290039 17.14255142211914 9 17.44827842712402 L 9 1.757637023925781 C 8.294201850891113 1.415507316589355 4.328344821929932 -0.3512897491455078 2 -1.384284973144531 M 0.9002609252929688 -3.977657318115234 C 1.015337944030762 -3.977643966674805 1.137924194335938 -3.952632904052734 1.265620231628418 -3.896440505981445 C 1.979680061340332 -3.582231521606445 9.40625 -0.2924404144287109 10 0.02176856994628906 C 10.59375 0.3359785079956055 11 0.9068889617919922 11 1.462199211120605 L 11 19.1257495880127 C 11 19.68105888366699 10.22968101501465 20.20348739624023 9.635930061340332 19.92069816589355 C 9.042180061340332 19.63790893554688 1.707819938659668 16.44397926330566 1 16.10932922363281 C 0.292180061340332 15.77468872070312 0 15.32147884368896 0 14.76617908477783 L 0 -2.994651794433594 C 0 -3.450653076171875 0.3718452453613281 -3.977714538574219 0.9002609252929688 -3.977657318115234 Z"
              stroke="none"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
