import * as FreeBrandsSvgIcons from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import LinkPng from 'src/assets/png/link_icon.png'
import { checkVimeoLink, checkYoutubeLink } from 'src/helpers/fns'

interface Props {
  readonly url?: string | null | undefined
}

export default function LinkIconComponent({ url }: Props): React.ReactElement {
  if (url == null) {
    return <img src={LinkPng} alt="" width={24} data-testid="linkImage" />
  }

  if (checkYoutubeLink(url)) {
    return <FontAwesomeIcon icon={FreeBrandsSvgIcons.faYoutube} size="xl" opacity={0.5} data-testid="youtubeIcon" />
  }

  if (checkVimeoLink(url)) {
    return <FontAwesomeIcon icon={FreeBrandsSvgIcons.faVimeoSquare} data-testid="vimeoIcon" />
  }

  return <img src={LinkPng} alt="" width={24} data-testid="linkImage" />
}
