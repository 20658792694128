import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useApi, useFormikUnload, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FormikTinyMce from 'src/views/components/forms/FormikTinyMce'
import Loader from '../../../../components/Loader'
import NoContent from '../../../../components/NoContent'

interface FormikValues {
  readonly duration: string
  readonly lecturers: string
  readonly hours: string
  readonly prerequisites: string
  readonly methods: string
  readonly mission: string
  readonly topics: string
  readonly outcomes: string
  readonly evaluation: string
  readonly resources: string
  readonly otherResources: string
  readonly schedule: string
  readonly knowledge: boolean
  readonly applying: boolean
  readonly judgments: boolean
  readonly communications: boolean
  readonly learning: boolean
  readonly values: boolean
}

export default function LecturerCoursesSyllabusPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('syllabus:syllabus')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const [searchParams] = useSearchParams()
  const activeSemester = useActiveSemester()
  const t = useTranslatable()
  const { courseId } = useParams()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const {
    data: syllabus,
    mutate: refetchSyllabus,
  }: APIResponse<Api.CourseSyllabus | null, Api.getLecturerCourseSyllabusErr> = useApi({
    endpoint: Api.getLecturerCourseSyllabus,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: courseId!,
        },
        query: {
          semId: semId!,
        },
      }),
      [courseId, headers, semId]
    ),
    shouldFetch: semId != null,
  })

  const [isEditing, setIsEditing] = React.useState(false)

  const submit = React.useCallback(
    async (body: FormikValues) => {
      try {
        await Api.patchLecturerCourseSyllabus({
          headers,
          args: {
            id: courseId!,
          },
          body: { ...syllabus, ...body },
        })
        void refetchSyllabus()

        toast.success(t('syllabus:syllabus_updated'))
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [courseId, headers, refetchSyllabus, syllabus, t]
  )

  return (
    <div className="relative my-3 w-full px-2 text-primaryTextColor">
      {(() => {
        // if (syllabus == null && syllabus.message != null) {
        //   return (
        //     <NoContent
        //       header={t('error:syllabus_not_found')}
        //       subHeader={syllabus.message}
        //       image={Warning}
        //       marginTop='5'
        //     />
        //   )
        // }
        // if (syllabus == null && syllabus.message == null) {
        //   return <Loader className='flex' style={{ margin: 'auto' }} />
        // }
        if (syllabus == null) {
          return <NoContent header={t('error:syllabus_not_found')} image={Warning} marginTop="5" />
        }

        const initialValues: FormikValues = {
          duration: syllabus.duration ?? '',
          lecturers: syllabus.lecturers,
          hours: syllabus.hours,
          prerequisites: syllabus.prerequisites,
          methods: syllabus.methods,
          mission: syllabus.mission,
          topics: syllabus.topics,
          outcomes: syllabus.outcomes,
          evaluation: syllabus.evaluation,
          resources: syllabus.resources,
          otherResources: syllabus.otherResources,
          schedule: syllabus.schedule,
          knowledge: syllabus.knowledge,
          applying: syllabus.applying,
          judgments: syllabus.judgments,
          communications: syllabus.communications,
          learning: syllabus.learning,
          values: syllabus.values,
        }

        return (
          <>
            {syllabus?.manage?.canEdit === true && (
              <a className="edit-icon absolute right-0 px-2" onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? (
                  <FontAwesomeIcon icon={ProRegularSvgIcons.faClose} style={{ fontSize: '1.5rem' }} />
                ) : (
                  <FontAwesomeIcon icon={ProRegularSvgIcons.faPencil} style={{ fontSize: '1rem' }} />
                )}
              </a>
            )}

            {isEditing ? (
              <Formik enableReinitialize initialValues={initialValues} onSubmit={submit}>
                {(formik) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useFormikUnload({ when: formik.dirty })

                  return (
                    <Form className={classnames('mb-3', { 'form-disabled': formik.isSubmitting })}>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('course:duration')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="duration" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('course:hours')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="hours" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('lecturer:lecturers')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="lecturers" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('course:prerequisites')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="prerequisites" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('syllabus:topics')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="topics" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('syllabus:outcomes')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="outcomes" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('syllabus:evaluation')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="evaluation" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('syllabus:resources')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="resources" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('syllabus:other_resources')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="otherResources" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('schedule:schedule')}:</dt>
                        </h6>
                        <div>
                          <FormikTinyMce name="schedule" />
                        </div>
                      </div>
                      <div className="mt-2">
                        <h6 className="my-3 font-bold">
                          <dt>{t('course:identify_the_competences_developed_by_this_course')}:</dt>
                        </h6>
                        <div>
                          <div>
                            <label>
                              <Field type="checkbox" name="knowledge" className="mr-2" />
                              {t('syllabus:knowledge_and_awarness')}
                            </label>
                          </div>
                          <div>
                            <label>
                              <Field type="checkbox" name="applying" className="mr-2" />
                              {t('syllabus:ability_to_practically_use_knowledge')}
                            </label>
                          </div>
                          <div>
                            <div>
                              <label>
                                <Field type="checkbox" name="judgments" className="mr-2" />
                                {t('syllabus:conclusions')}
                              </label>
                            </div>
                            <div>
                              <label>
                                <Field type="checkbox" name="communications" className="mr-2" />
                                {t('resume:communication_skills')}
                              </label>
                            </div>

                            <div>
                              <label>
                                <Field type="checkbox" name="learning" className="mr-2" />
                                {t('syllabus:ability_to_learn')}
                              </label>
                            </div>

                            <div>
                              <label>
                                <Field type="checkbox" name="values" className="mr-2" />
                                {t('syllabus:values')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 flex w-full justify-end">
                        <Button variant="blue" type="submit" disabled={formik.isSubmitting}>
                          {formik.isSubmitting && (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                              style={{ marginRight: 7 }}
                            />
                          )}
                          {t('common:save')}
                        </Button>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            ) : (
              <div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('course:duration')}:
                  </p>
                  {syllabus.duration != null ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.duration ?? '' }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">{t('course:hours')}:</p>
                  {syllabus.hours.length > 0 ? (
                    <p className="syllabus-content text-title" dangerouslySetInnerHTML={{ __html: syllabus.hours }} />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('lecturer:lecturers')}:
                  </p>
                  {syllabus.lecturers.length > 0 ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.lecturers }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('course:prerequisites')}:
                  </p>
                  {syllabus.prerequisites.length > 0 ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.prerequisites }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold first-letter:first-line:marker:text-headline">
                    {t('syllabus:methods')}:
                  </p>
                  {syllabus.methods.length > 0 ? (
                    <p className="syllabus-content text-title" dangerouslySetInnerHTML={{ __html: syllabus.methods }} />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('syllabus:mission')}:
                  </p>
                  {syllabus.mission.length > 0 ? (
                    <p className="syllabus-content text-title" dangerouslySetInnerHTML={{ __html: syllabus.mission }} />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('syllabus:topics')}:
                  </p>
                  {syllabus.topics.length > 0 ? (
                    <p className="syllabus-content text-title" dangerouslySetInnerHTML={{ __html: syllabus.topics }} />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}{' '}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('syllabus:outcomes')}:
                  </p>
                  {syllabus.outcomes.length > 0 ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.outcomes }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('syllabus:evaluation')}:
                  </p>
                  {syllabus.evaluation.length > 0 ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.evaluation }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('syllabus:resources')}:
                  </p>
                  {syllabus.resources.length > 0 ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.resources }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}{' '}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('syllabus:other_resources')}:
                  </p>
                  {syllabus.otherResources.length > 0 ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.otherResources }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
                <div>
                  <p className="mb-3 border-b-DEFAULT border-[#E6E6E6] text-headline font-bold">
                    {t('schedule:schedule')}:
                  </p>
                  {syllabus.schedule.length > 0 ? (
                    <p
                      className="syllabus-content text-title"
                      dangerouslySetInnerHTML={{ __html: syllabus.schedule }}
                    />
                  ) : (
                    <span className="flex justify-center">-</span>
                  )}
                </div>
              </div>
            )}
          </>
        )
      })()}
    </div>
  )
}
