export function PinIcon(): JSX.Element {
  return (
    <svg id="pin_icon" xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12">
      <g id="Path_9" data-name="Path 9" fill="none">
        <path d="M4.5,0A4.5,4.5,0,0,1,9,4.5C9,8.077,4.5,12,4.5,12S0,8,0,4.5A4.5,4.5,0,0,1,4.5,0Z" stroke="none" />
        <path
          d="M 4.5 1 C 2.570089817047119 1 1 2.570090293884277 1 4.5 C 1 6.604287624359131 3.141098976135254 9.230692863464355 4.505504608154297 10.61990737915039 C 5.868027687072754 9.250741958618164 8 6.651570796966553 8 4.5 C 8 2.570090293884277 6.429909706115723 1 4.5 1 M 4.5 0 C 6.98528003692627 0 9 2.01471996307373 9 4.5 C 9 8.077369689941406 4.5 12 4.5 12 C 4.5 12 0 7.998589992523193 0 4.5 C 0 2.01471996307373 2.01471996307373 0 4.5 0 Z"
          stroke="none"
          fill="gray"
        />
      </g>
      <circle id="Ellipse_8" data-name="Ellipse 8" cx="1.5" cy="1.5" r="1.5" transform="translate(3 3)" fill="gray" />
    </svg>
  )
}
