import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormikContext, type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import { Button } from 'src/tailwind/components/Button'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextInput from 'src/views/components/forms/formik/TextInput'

interface FormikValues {
  readonly title: string
  readonly url: string | null
}

export default function LecturerCoursesMaterialsEditPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:materials')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const headers = useAuthenticatedHeaders()
  const { courseId, fileId } = useParams()
  const [searchParams] = useSearchParams()
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const { data: material, mutate } = useApi({
    endpoint: Api.getLecturerCoursesMaterial,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          semId: semId!,
        },
        args: {
          id: courseId!,
          fileId: fileId!,
        },
      }),
      [courseId, fileId, headers, semId]
    ),
  })
  const locale = useLocale()
  const t = useTranslatable()
  const initialValues: FormikValues = { title: material.name ?? '', url: material.url ?? '' }
  const navigate = useNavigate()

  async function onSubmit(values: FormikValues, formikHelpers: FormikHelpers<FormikValues>): Promise<void> {
    const { title, url } = values

    await Api.patchLecturerCoursesMaterials({
      headers,
      body: {
        semId: semId!,
        title,
        url: url!.length > 0 ? url : null,
        fileUid: material?.mediaFile !== undefined ? material.mediaFile.id : null,
      },
      args: {
        id: courseId!,
        fileId: fileId!,
      },
    })
    void mutate()

    toast.success(t('course:file_updated'))
    formikHelpers.resetForm()
    setSuccessfullySubmited(true)
  }

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/courses/${courseId!}/materials?semId=${semId!}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  return (
    <>
      <h3 className="font-bold">{t('course:edit_material')}</h3>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        classNames={{ form: 'w-full' }}
        isConfirmable={!successfullySubmited}
      >
        <FormContent material={material} />
      </Form>
    </>
  )
}

function FormContent({ material }: { material: Api.getLecturerCoursesMaterialOk }): React.ReactElement | null {
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const { courseId } = useParams()
  const [searchParams] = useSearchParams()
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id

  // const patchPending = useStore(Api.patchLecturerCoursesMaterials.pending)
  // TODO
  const patchPending = false
  const locale = useLocale()

  return (
    <div className="mt-6 w-full rounded-card bg-card px-6 py-9">
      <FormError />
      <TextInput
        type="text"
        name="title"
        placeholder={t('message:enter_the_title')}
        required
        label={t('common:title')}
      />
      <TextInput
        type="text"
        name="url"
        label={t('file:file_url')}
        placeholder={material?.url != null ? t('common:enter_the_url') : ''}
        disabled={material?.mediaFile !== undefined}
      />
      <div className="mb-3">
        <label className="flex pl-0 font-bold text-primaryTextColor">{t('file:attached_files')}:</label>
        <div className="mb-2 flex text-primaryTextColor sm:pl-0">
          {material?.mediaFile != null ? (
            <>
              <p>{material?.mediaFile.originalName}</p>
              <a
                href={`/${locale}/media-files/${material.mediaFile.id}/download`}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2 text-primaryTextColor"
                style={{ height: 'fit-content' }}
              >
                <FontAwesomeIcon icon={ProSolidSvgIcons.faDownload} />
              </a>
            </>
          ) : (
            '...'
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-wrap justify-end">
        <BackButton link={`/${locale}/lecturer/courses/${courseId!}/materials?semId=${semId!}`} />
        <Button
          type="submit"
          className="ml-2"
          variant="red"
          disabled={
            !formik.isValid ||
            patchPending ||
            formik.values.title === '' ||
            (material.url !== null ? formik.values.url === '' : false)
          }
        >
          {t('common:save')}
        </Button>
      </div>
    </div>
  )
}
