import { t as tt } from 'i18next'
import { defered } from 'src/helpers/defered'
import { identity } from 'src/helpers/fns'
import { observableValue, type ObservableValue } from 'src/helpers/observable'
import type { AnyHeaders } from 'src/hooks/auth/app'
import { refreshAuthObject } from 'src/hooks/auth/refreshAuthObject'
import { refreshTokenStorage } from 'src/hooks/auth/refreshTokenStorage'
import type { UploadConfig } from './UploadConfig'
import {
  AuthErrorDecoder,
  DataDecoder,
  ErrorMessageDecoder,
  ErrorsObjectDecoder,
  type AuthError,
  type ErrorMessage,
  type ErrorsObject,
} from './base'
import { getAuthorization } from './common'
import { MediaFileDecoder, type LanguageString, type MediaFile } from './types'

export interface postMediaFilesUploadParams {
  readonly headers: AnyHeaders
  readonly file: File
  readonly config: UploadConfig
}

export interface UploadFileReturn {
  readonly result: Promise<MediaFile>
  readonly progress: ObservableValue<number>
  readonly cancel: () => void
}

export async function postMediaFilesUpload({
  file,
  headers,
  config,
}: postMediaFilesUploadParams): Promise<UploadFileReturn> {
  let canceled = false
  let canceler: null | (() => void) = null
  const progress = observableValue<number>(0)
  const result = defered<MediaFile>()

  if (file.size > config.max_upload_size) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      type: 'ErrorMessage',
      message: tt('error:file_is_larger'),
    }
  }

  const fileNameChunks = file.name.split('.')
  const ext = fileNameChunks[fileNameChunks.length - 1]!

  if (!config.allowed_extensions.includes(ext)) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw {
      type: 'ErrorMessage',
      message: tt('error:file_extension_not_allowed'),
    }
  }

  const chunkSize = config.max_chunk_size
  const chunkCount = Math.ceil(file.size / chunkSize)

  void (async () => {
    try {
      let mediaFile: MediaFile | undefined

      // eslint-disable-next-line no-unmodified-loop-condition
      for (let i = 0; i < chunkCount && !canceled; i++) {
        const start = i * chunkSize
        const end = Math.min(start + chunkSize, file.size)

        const headersObj = {
          Authorization: headers.Authorization,
          'Profile-ID': headers['Profile-ID'],
          'Accept-Language': headers['Accept-Language'],
        }

        const upload = uploadFile({
          config,
          file,
          headers: headersObj,
          uid: mediaFile?.id,
          slice: { start, end },
        })
        canceler = upload.cancel

        upload.progress.eventEmitter.addListener('update', (state) => {
          progress.setValue(i * (100 / chunkCount) + state / chunkCount)
        })

        mediaFile = await upload.result

        canceler = null
      }

      if (canceled) {
        result.reject(new Error('Upload Canceled!'))
      } else if (mediaFile != null) {
        result.resolve(mediaFile)
      } else {
        result.reject(new Error('File Not Found!'))
      }
    } catch (err) {
      result.reject(err)
    }
  })()

  return {
    result: result.promise,
    progress,
    cancel() {
      canceled = true

      if (canceler != null) {
        canceler()
      }
    },
  }
}

interface UploadFileParams {
  readonly config: UploadConfig
  readonly file: File
  readonly slice?: {
    readonly start: number
    readonly end: number
  }
  readonly uid?: string
  readonly headers: {
    readonly Authorization?: string
    readonly 'Profile-ID'?: string
    readonly 'Accept-Language': LanguageString
  }
}

function uploadFileL1({ file, slice, uid, headers, config }: UploadFileParams): UploadFileReturn {
  const data = new FormData()

  data.append('uid', uid!)

  const chunk =
    slice != null
      ? new File([file.slice(slice.start, slice.end)], file.name, {
          lastModified: file.lastModified,
          type: file.type,
        })
      : file

  data.append('file', chunk, file.name)

  const headersArray = Object.entries(headers)

  headersArray.push(['Accept', 'application/json'])

  if (slice != null) {
    headersArray.push(['Content-Range', `bytes ${slice.start}-${slice.end - 1}/${file.size}`])
  }

  const request = new XMLHttpRequest()

  request.open('POST', config.url)

  headersArray.forEach(([name, value]) => {
    request.setRequestHeader(name, value)
  })

  const progress = observableValue<number>(0)
  const result = defered<MediaFile>()

  request.upload.addEventListener('progress', (e) => {
    progress.setValue((e.loaded / e.total) * 100)
  })

  request.addEventListener('load', () => {
    try {
      result.resolve(guardMediaFilesUpload(request.status, JSON.parse(request.response)))
    } catch (err) {
      result.reject(err)
    }
  })
  request.addEventListener('abort', result.reject)
  request.addEventListener('error', result.reject)
  request.addEventListener('timeout', result.reject)

  request.send(data)

  return {
    result: result.promise,
    progress,
    cancel: () => {
      request.abort()
    },
  }
}

function uploadFile(params: UploadFileParams): UploadFileReturn {
  let canceled = false
  const progress = observableValue<number>(0)
  const result = defered<MediaFile>()

  const refreshToken = refreshTokenStorage.getValue()?.authObject.refreshToken
  const authEnabled = params.headers.Authorization != null && refreshToken != null

  let request: UploadFileReturn | undefined

  function handleUpload(): void {
    if (canceled) {
      result.reject(new Error('Upload Canceled!'))
    }

    request = uploadFileL1(
      authEnabled
        ? {
            ...params,
            headers: { ...params.headers, Authorization: getAuthorization(refreshTokenStorage.getValue()!.authObject) },
          }
        : params
    )
    request.progress.eventEmitter.on('update', progress.setValue)

    request.result.then(result.resolve, (err) => {
      if ('type' in err && err.type === 'AuthError') {
        refreshAuthObject(refreshToken!).then(handleUpload, result.reject)
      } else {
        result.reject(err)
      }
    })
  }

  handleUpload()

  return {
    result: result.promise,
    progress,
    cancel: () => {
      canceled = true
      request?.cancel()
    },
  }
}

function guardMediaFilesUpload(status: number, data: unknown): MediaFile {
  // 2XX MediaFile
  if (status >= 200 && status <= 299) {
    const result = DataDecoder(MediaFileDecoder).decode(data)
    if (result.ok) {
      return identity<MediaFile>(result.value)
    } else {
      console.error('POST /media-files/upload 2XX null\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // 401 AuthError
  if (status === 401) {
    const result = DataDecoder(AuthErrorDecoder).decode(data)
    if (result.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw identity<AuthError>(result.value)
    } else {
      console.error('POST /media-files/upload 401 AuthError\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // 422 ErrorsObject
  if (status === 422) {
    const result = DataDecoder(ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw identity<ErrorsObject>(result.value)
    } else {
      console.error('GET /media-files/upload 422 ErrorsObject\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // XXX ErrorMessage
  {
    const result = DataDecoder(ErrorMessageDecoder).decode(data)
    if (result.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw identity<ErrorMessage>(result.value)
    } else {
      console.error('POST /media-files/upload XXX ErrorMessage\n' + '\n' + (result.error?.text ?? ''))
    }
  }

  // fallback ErrorMessage
  // eslint-disable-next-line @typescript-eslint/no-throw-literal
  throw identity<ErrorMessage>({
    type: 'ErrorMessage',
    message: 'Could not process response!',
  })
}
