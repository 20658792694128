import { type FormikHelpers } from 'formik'
import * as React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { EmailIcon } from 'src/assets/icons/customIcons/Email'
import { XlsIcon } from 'src/assets/icons/customIcons/Xls'
import { CourseChosenIcon } from 'src/assets/icons/customIcons/course-icons/CourseChosen'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import * as Table from 'src/tailwind/components/Table'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { Form } from 'src/views/components/forms/formik/Form'
import MessageForm from './MessageForm'

interface MessageValues {
  readonly subject: string
  readonly body: string
}
const messageValues = { subject: '', body: '' }

interface Props {
  teachingGroups: Api.getLecturerTeachingGroupsOk
  groupsPaperPending: boolean
  groups: string[]
  setGroups: React.Dispatch<React.SetStateAction<string[]>>
}

export default function GroupsTable({
  teachingGroups,
  groupsPaperPending,
  groups,
  setGroups,
}: Props): React.ReactElement | null {
  const { courseId } = useParams()
  const [show, setShow] = React.useState(false)
  const locale = useLocale()
  const [searchParams, setSearchParams] = useSearchParams()
  const semId = searchParams.get('semId')
  const groupId = searchParams.get('groupId')
  const headers = useAuthenticatedHeaders()
  const theme = useTheme()
  const editMultiple = searchParams.get('editMultiple')
  const t = useTranslatable()
  const filteredTeachingGroups = React.useMemo(() => {
    return editMultiple != null && Boolean(editMultiple)
      ? teachingGroups.filter((group) => group.paper == null)
      : teachingGroups
  }, [teachingGroups, editMultiple])
  const onSubmit = React.useCallback(
    async (values: MessageValues, helpers: FormikHelpers<MessageValues>): Promise<void> => {
      const { subject, body } = values
      try {
        await Api.postMessagesToGroups({
          headers,
          args: {
            courseId: courseId!,
          },
          body: {
            groups,
            subject,
            body,
          },
          query: { semId: semId! },
        })
        setShow(false)
        helpers.resetForm()
      } catch (error) {
        console.error(error)
      }
    },
    [courseId, groups, headers, semId]
  )

  const groupClikcHandler = React.useCallback(
    (group: Api.Group) => {
      if (editMultiple != null && Boolean(editMultiple)) {
        if (groups.includes(group.id)) {
          setGroups(groups.filter((id) => id !== group.id))
        } else {
          setGroups([...groups, group.id])
        }
      } else {
        if (groupId === group.id) {
          searchParams.delete('groupId')
          setSearchParams(searchParams)
        } else {
          setSearchParams({ semId: searchParams.get('semId')!, groupId: group.id })
        }
      }
    },
    [editMultiple, groups, searchParams, setGroups, setSearchParams, groupId]
  )
  const checkAllGroups = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setGroups(filteredTeachingGroups.map((group) => group.id))
      } else {
        setGroups([])
      }
    },
    [filteredTeachingGroups, setGroups]
  )
  if (groupsPaperPending) return <Loader className="m-auto flex" />
  if (teachingGroups == null) {
    return <NoContent header={t('error:records_not_found')} image={Warning} marginTop="5" />
  }

  return (
    <>
      <div className="flex w-full justify-end">
        <a
          href={`/${locale}/lecturer/courses/${courseId!}/teaching-groups/export?semId=${semId!}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex h-fit rounded-full p-2 text-primaryTextColor hover:bg-hover-icon"
        >
          <div className="mr-2 h-fit w-[18px]">
            <XlsIcon width="18" height="18" />
          </div>
          {t('common:export')}
        </a>
      </div>
      <div className="mb-[25px] mt-2 flex w-full flex-col items-end">
        <Table.Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th scope="col">
                <input
                  type="checkbox"
                  onChange={({ target }) => checkAllGroups(target.checked)}
                  checked={groups.length === filteredTeachingGroups.length}
                  className="cursor-pointer border border-borderColor dark:bg-primaryWhite"
                />
              </Table.Th>
              <Table.Th scope="col" className="pl-0 text-start">
                {t('group:groups')}
              </Table.Th>
              <Table.Th scope="col" className="text-start">
                {t('lecturer:lecturers')}
              </Table.Th>
              <Table.Th align="center" scope="col" className="whitespace-nowrap !text-center">
                {t('common:status')}
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {teachingGroups.map((group) => (
              <Table.Tr key={group.id} className={` ${groupId === group.id ? 'bg-primaryGreen/50' : ''}`}>
                <Table.Td className="align-middle">
                  {group.paper != null && editMultiple != null && (
                    <Tooltip id={`disable-checkbox-${group.id}`} variant={theme === 'dark' ? 'dark' : 'light'}>
                      {t('group:group_have_paper')}
                    </Tooltip>
                  )}
                  <div data-tooltip-id={`disable-checkbox-${group.id}`}>
                    <input
                      type="checkbox"
                      checked={groups.includes(group.id)}
                      onChange={() => {
                        if (groups.includes(group.id)) {
                          setGroups(groups.filter((id) => id !== group.id))
                        } else {
                          if (editMultiple != null && group.paper != null) return
                          setGroups([...groups, group.id])
                        }
                      }}
                      disabled={editMultiple != null && group.paper != null}
                      className="cursor-pointer border border-borderColor dark:bg-primaryWhite"
                    />
                  </div>
                </Table.Td>
                <Table.Td className="items-start pl-0">
                  <button
                    className="pl-0 text-start text-primaryBlueLink no-underline"
                    onClick={() => groupClikcHandler(group)}
                  >
                    {group.name}- (0-{group.maxQuota})
                    <span>
                      {' '}
                      {t('common:total')} - {group.choiceCount?.count}
                    </span>
                  </button>
                </Table.Td>
                <Table.Td className="pl-0">
                  <div className="flex flex-wrap items-start">
                    {group.lecturers?.map((lecturer) => (
                      <div key={lecturer.id} className="my-1 flex items-center">
                        {lecturer.photoUrl != null ? (
                          <img src={lecturer.photoUrl} alt="studentAvatar" width="30px" />
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: lecturer.avatar }} />
                        )}
                        <span style={{ fontSize: '14px' }} className="mx-2">
                          {lecturer.fullName}
                        </span>
                      </div>
                    ))}
                  </div>
                </Table.Td>
                <Table.Td className="p-3">
                  <div className="flex justify-center">
                    {group.paper == null ? (
                      <>
                        <Tooltip id="noPaper" variant={theme === 'dark' ? 'dark' : 'light'}>
                          {t('group:group_does_not_have_paper')}
                        </Tooltip>
                        <div data-tooltip-id="noPaper" className="cursor-help p-2">
                          <CourseChosenIcon />
                        </div>
                      </>
                    ) : group.paper.active ? (
                      <>
                        <Tooltip id="active" variant={theme === 'dark' ? 'dark' : 'light'}>
                          {t('group:group_has_active_status')}
                        </Tooltip>
                        <div data-tooltip-id="active" className="cursor-help p-2">
                          <CourseCompletedIcon />
                        </div>
                      </>
                    ) : (
                      <>
                        <Tooltip id="passive" variant={theme === 'dark' ? 'dark' : 'light'}>
                          {t('group:group_has_passive_status')}
                        </Tooltip>
                        <div data-tooltip-id="passive" className="cursor-help p-2">
                          <CourseFailedIcon />
                        </div>
                      </>
                    )}
                  </div>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.Table>
        {editMultiple == null && (
          <Button
            variant="blue"
            className="mt-2"
            disabled={groups.length < 1}
            type="button"
            onClick={() => setShow(true)}
          >
            {t('message:send_message')}
          </Button>
        )}
      </div>
      <Modal
        title={t('message:messages_compose')}
        icon={<EmailIcon />}
        isModalOpen={show}
        onClose={() => setShow(false)}
      >
        {show && (
          <Form
            initialValues={messageValues}
            onSubmit={onSubmit}
            classNames={{
              form: 'overflow-auto py-4',
            }}
            isConfirmable
          >
            <MessageForm>
              {teachingGroups
                .filter((group) => groups.includes(group.id))
                .map((group, key) => (
                  <div className="mr-2" key={group.id}>
                    {group.name} {key === groups.length - 1 ? '' : ','}{' '}
                  </div>
                ))}
            </MessageForm>
          </Form>
        )}
      </Modal>
    </>
  )
}
