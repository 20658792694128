import * as ProRegularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { format, parseISO } from 'date-fns'
import { useFormikContext } from 'formik'
import { t as tt } from 'i18next'
import Plyr from 'plyr-react'
import 'plyr-react/plyr.css'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { DownloadIcon } from 'src/assets/icons/customIcons/Download'
import VideoIcon from 'src/assets/icons/customIcons/Video'
import InfoIcon from 'src/assets/icons/customIcons/page-icons/info'
import ViewIcon from 'src/assets/icons/customIcons/page-icons/view'
import { DeleteIcon } from 'src/assets/icons/customIcons/upload-icons/Delete'
import { checkVimeoLink, checkYoutubeLink, formatFileSize, shortenString } from 'src/helpers/fns'
import { useAllSearchParams, useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useDateLocale, useTranslatable } from 'src/hooks/locale/utils'
import { useActiveSemester } from 'src/hooks/semesters'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Button } from 'src/tailwind/components/Button'
import { Modal } from 'src/tailwind/components/Modal'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import FileIcon from 'src/views/components/FileIcon'
import LinkIconComponent from 'src/views/components/LinkIcon'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Pagination from 'src/views/components/Pagination'
import PopoverComponent from 'src/views/components/PopoverComponent'
import UserPhoto from 'src/views/components/UserPhoto'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Swal from 'sweetalert2'

interface FormikValues {
  readonly query: string
  readonly author: string
}
const initialValues: FormikValues = { query: '', author: '0' }

export default function LecturerCoursesMaterialsPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('course:materials')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [openMaterial, setOpenMaterial] = React.useState<null | Api.CourseFile>(null)
  const headers = useAuthenticatedHeaders()
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useParams()
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const author = searchParams.get('author')
  const query = searchParams.get('query')
  const page = searchParams.get('page')
  const perPage = searchParams.get('perPage')
  const semId = semIdParam ?? activeSemester?.id
  const allSearchParams = useAllSearchParams()
  const t = useTranslatable()
  const locale = useLocale()
  const dateLocale = useDateLocale()
  const theme = useTheme()
  const {
    data: materials,
    mutate,
    isValidating: materialsPending,
  } = useApi({
    endpoint: Api.getLecturerCoursesMaterials,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.courseId!,
        },
        query: {
          filters: {
            author,
            search: query,
          },
          page,
          perPage,
          semId: semId!,
        },
      }),
      [author, headers, page, params.courseId, query, semId, perPage]
    ),
    suspense: false,
    shouldFetch: semId != null,
  })

  const deleteMaterial = React.useCallback(
    async (fileId: string) => {
      try {
        await Api.deleteLecturerCoursesMaterials({
          headers,
          body: {
            semId: semId!,
          },
          args: {
            id: params.courseId!,
            fileId,
          },
        })
        toast.success(t('course:file_deleted'))
      } catch (error) {
        toast.error(t('course:file_not_deleted'))
      }
    },
    [headers, params.courseId, semId, t]
  )

  const downloadFile = React.useCallback(
    async (id: string) => {
      const result = await Api.getMediaFilesDownload({
        headers,
        args: {
          id,
        },
      })
      // @ts-expect-error TODO fix issue with types
      setOpenMaterial(result)
    },
    [headers]
  )
  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={async (values: FormikValues) => {
          setSearchParams({
            ...allSearchParams,
            ...values,
          })
        }}
        classNames={{
          form: 'w-full px-2 mt-3 mb-1',
        }}
        enableReinitialize
      >
        <React.Suspense fallback={<Loader className="m-auto flex" />}>
          <ErrorBoundary errorElement={<ErrorElement />}>
            <FormSearch disabled={materialsPending} />
          </ErrorBoundary>
        </React.Suspense>
      </Form>
      {(() => {
        if (materials?.meta == null) return null
        if (materials.data != null && materials.data.length === 0 && !materialsPending) {
          return <NoContent header={t('error:records_not_found')} image={Warning} marginTop="5" />
        }
        return (
          <div className="w-full py-3">
            {/* <Pagination pagination={materials.meta.pagination} /> */}
            {materialsPending ? (
              <Loader className="flex" style={{ margin: 'auto' }} />
            ) : (
              <Table.Table className="mt-4">
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th scope="col" />
                    <Table.Th scope="col">{t('common:name')}</Table.Th>
                    <Table.Th scope="col" className="text-center">
                      {t('common:size')}
                    </Table.Th>
                    <Table.Th scope="col" className="whitespace-nowrap text-center">
                      {t('lecturer:lecturer')}
                    </Table.Th>
                    <Table.Th scope="col" className="whitespace-nowrap text-center">
                      {t('common:edit_delete')}
                    </Table.Th>
                    <Table.Th scope="col" />
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {materials.data?.map((material) => (
                    <Table.Tr key={material.id}>
                      <Table.Td className="pl-3 align-middle">
                        {material.url != null ? (
                          <LinkIconComponent url={material.url} />
                        ) : (
                          <FileIcon type={material.mediaFile?.extension} />
                        )}
                      </Table.Td>
                      <Table.Td style={{ minWidth: '250px' }}>
                        {material.url != null ? material.name : material.mediaFile?.title}
                      </Table.Td>
                      <Table.Td className="whitespace-nowrap align-middle">
                        {material.mediaFile != null ? formatFileSize(material.mediaFile.size) : '-'}
                      </Table.Td>
                      <Table.Td className="whitespace-nowrap pr-4 align-middle">
                        <UserPhoto className="scale-[0.7]" user={material.user} />{' '}
                        <Link
                          to={`/${locale}/users/${material.user!.uid}`}
                          className="ml-2 whitespace-nowrap align-middle hover:underline"
                          data-tooltip-id={`${material.id}-lecturer`}
                        >
                          <span className="ml-2 text-primaryBlueLink">{material.user?.fullName}</span>
                        </Link>
                        <Tooltip
                          id={`${material.id}-lecturer`}
                          place="top"
                          variant={theme === 'dark' ? 'dark' : 'light'}
                          className="m-0 p-1"
                        >
                          <p className="mb-0">{t('lecturer:lecturers_page')}</p>
                        </Tooltip>
                      </Table.Td>
                      <Table.Td className="px-5 text-center align-middle">
                        <div className="flex w-full items-center justify-center">
                          <Link
                            to={`edit/${material.id}?semId=${searchParams.get('semId')!}`}
                            className={classNames(
                              'mr-2 flex h-7 w-7 items-center justify-center rounded-full p-1 hover:bg-table-icon-hover',
                              {
                                disabled: material.manage?.canEdit === false,
                              }
                            )}
                          >
                            {/* შესაცვლელი აიქონი */}
                            <FontAwesomeIcon
                              icon={ProRegularSvgIcons.faPencil}
                              className="mt-[3px]"
                              color="gray"
                              height={25}
                              width={25}
                              size="lg"
                            />
                          </Link>

                          <button
                            className={classNames(
                              'flex h-7 w-7 items-center justify-center rounded-full p-1 hover:bg-table-icon-hover',
                              {
                                disabled: material.manage?.canEdit === false,
                              }
                            )}
                            onClick={() => {
                              void (async () => {
                                const alert = await Swal.fire({
                                  title: t('common:do_you_really_want_to_delete'),
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: '#0D6EFD',
                                  cancelButtonColor: '#6C757D',
                                  confirmButtonText: t('common:confirm'),
                                  cancelButtonText: t('common:cancel'),
                                })
                                if (alert.isConfirmed) {
                                  void deleteMaterial(material.id)
                                  void mutate()
                                }
                              })()
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </Table.Td>
                      <Table.Td className="w-[160px] whitespace-nowrap px-4 text-center align-middle">
                        <div className="flex justify-between">
                          <button
                            onClick={() => {
                              if (material.mediaFile != null) {
                                void downloadFile(material.mediaFile.id)
                              } else {
                                setOpenMaterial(material)
                              }
                            }}
                            className={classNames(
                              'flex h-7 w-7 items-center justify-center rounded-full p-1 hover:bg-table-icon-hover',
                              {
                                'cursor-not-allowed opacity-50': !material.isViewableInWeb,
                              }
                            )}
                            disabled={!material.isViewableInWeb}
                          >
                            <ViewIcon />
                          </button>
                          {material.mediaFile != null && (
                            <a
                              href={`/${locale}/media-files/${material.mediaFile.id}/download`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex size-7 items-center justify-center rounded-full p-1 hover:bg-table-icon-hover"
                            >
                              <DownloadIcon />
                            </a>
                          )}
                          {material.url != null && (
                            <a
                              href={material.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex size-7 items-center justify-center rounded-full hover:bg-table-icon-hover"
                            >
                              <LinkIconComponent url={material.url} />
                            </a>
                          )}

                          <PopoverComponent
                            label={<InfoIcon />}
                            labelClassName="h-7 w-7 rounded-full flex justify-center items-center hover:bg-table-icon-hover"
                            disabled={material.mediaFile == null}
                          >
                            <p>
                              <strong>{t('common:title')}</strong> {material.mediaFile?.title}
                            </p>
                            <p>
                              <strong>{t('common:type')}</strong> {material.mediaFile?.mimeType}
                            </p>
                            <p>
                              <strong>{t('common:extension')}</strong> {material.mediaFile?.extension}
                            </p>
                            <p>
                              <strong>{t('common:size')}</strong>
                              {material.mediaFile != null && formatFileSize(material.mediaFile.size)}
                            </p>
                            <p className="mb-0">
                              <strong>{t('common:date')}</strong>{' '}
                              {material.mediaFile != null &&
                                format(parseISO(material.mediaFile.createdAt), 'd MMMM yyyy, HH:mm', {
                                  locale: dateLocale,
                                })}
                            </p>
                          </PopoverComponent>
                        </div>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table.Table>
            )}
            <Pagination pagination={materials.meta.pagination} />
          </div>
        )
      })()}
      {openMaterial != null && (
        <Modal
          title={
            openMaterial?.url != null ? (
              <a href={openMaterial.url} target="_blank" rel="noreferrer">
                {shortenString(openMaterial.url, 40)}
              </a>
            ) : (
              shortenString(openMaterial?.mediaFile?.title, 40)
            )
          }
          isModalOpen={openMaterial != null}
          onClose={() => setOpenMaterial(null)}
          icon={<VideoIcon width="24" height="24" />}
        >
          <Player openMaterial={openMaterial} />
        </Modal>
      )}
    </>
  )
}

interface PlayerProps {
  openMaterial: Api.CourseFile
}
function Player({ openMaterial }: PlayerProps): JSX.Element | null {
  const t = useTranslatable()

  const renderMaterial = (): JSX.Element | null => {
    if (openMaterial?.mediaFile != null) {
      const { mimeType } = openMaterial.mediaFile

      if (mimeType.startsWith('video/') || mimeType.startsWith('audio/')) {
        return (
          <Plyr
            source={{
              type: mimeType.startsWith('video/') ? 'video' : 'audio',
              sources: [{ provider: 'html5', src: openMaterial.url! }],
            }}
            options={{
              controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
              autoplay: true,
            }}
          />
        )
      } else {
        return (
          <div className="m-auto text-center" role="alert">
            <p>{tt('common:unhandled_type', { type: mimeType })}</p>
            <p className="mb-0">
              <a target="_blank" rel="noopener noreferrer" href={openMaterial.mediaFile.downloadEndpoint}>
                {t('file:download_file')}
              </a>
            </p>
          </div>
        )
      }
    }

    if (openMaterial?.url != null) {
      const { url } = openMaterial

      if (checkYoutubeLink(url)) {
        return (
          <Plyr
            source={{
              type: 'video',
              sources: [{ provider: 'youtube', src: url }],
            }}
            options={{
              controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
              autoplay: true,
            }}
          />
        )
      }

      if (checkVimeoLink(url)) {
        return (
          <Plyr
            source={{
              type: 'video',
              sources: [{ provider: 'vimeo', src: url }],
            }}
            options={{
              controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
              autoplay: true,
            }}
          />
        )
      }

      return (
        <div className="m-auto text-center" role="alert">
          <p>{t('error:could_not_handle_url')}</p>
          <p className="mb-0">
            <a target="_blank" rel="noopener noreferrer" href={url}>
              {url}
            </a>
          </p>
        </div>
      )
    }

    return null
  }

  return renderMaterial()
}

function FormSearch({ disabled }: { disabled: boolean }): React.ReactElement | null {
  const formik = useFormikContext<FormikValues>()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useParams()
  const activeSemester = useActiveSemester()
  const semIdParam = searchParams.get('semId')
  const semId = semIdParam ?? activeSemester?.id
  const [buttonDisable, setButtonDisable] = React.useState(true)
  const { data: coursesLecturers, isValidating } = useApi({
    endpoint: Api.getCoursesLecturers,
    params: React.useMemo(
      () => ({
        headers,
        args: { id: params.courseId! },
        query: { semId: semId! },
      }),
      [headers, params.courseId, semId]
    ),
    shouldFetch: semId != null,
  })

  React.useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semId])

  return (
    <fieldset disabled={disabled} className="flex w-full flex-col justify-between">
      <FormError />
      <div className="mb-3 flex w-full flex-wrap justify-between">
        <div className="mr-0 w-full min-w-[100px] md:!mb-0 md:mr-2 md:w-[49%]">
          <TextInput
            type="text"
            label={t('common:search_for_keywords')}
            name="query"
            placeholder={t('common:search')}
          />
        </div>
        <div className="w-full min-w-[100px] md:w-[49%]">
          <SelectInput
            label={t('lecturer:select_lecturer')}
            name="author"
            options={
              coursesLecturers != null && coursesLecturers.length > 0
                ? [
                    { label: t('lecturer:all_lecturers'), value: '0' },
                    ...coursesLecturers.map((lecturer) => ({
                      label: lecturer.fullName,
                      value: lecturer.id,
                    })),
                  ]
                : []
            }
            disabled={disabled || isValidating}
            isLoading={isValidating}
          />
        </div>
      </div>

      <div className="flex w-full justify-end">
        <Button
          variant="red"
          onClick={() => setButtonDisable(false)}
          disabled={
            formik.isSubmitting || (formik.values.query.length < 1 && formik.values.author === '0' && buttonDisable)
          }
        >
          {t('common:search')}
        </Button>
        <Button
          variant="yellow"
          onClick={() => {
            setSearchParams({ semId: semId! })
            formik.resetForm()
            setButtonDisable(true)
          }}
          type="reset"
          disabled={
            formik.isSubmitting || (formik.values.query.length < 1 && formik.values.author === '0' && buttonDisable)
          }
          className="ml-2"
        >
          {t('common:cancel')}
        </Button>
      </div>
    </fieldset>
  )
}
