import { Link } from 'react-router-dom'

interface Props {
  title: string
  quantity: number | string | null
  icon: JSX.Element
  text?: string
  className?: string
  to: string
}

export default function InfoCard({ to, title, icon, text, quantity, className, ...props }: Props): JSX.Element {
  return (
    <div {...props} className={`h-36 cursor-pointer rounded-card bg-card hover:bg-hover-card ${className ?? ''}`}>
      <Link to={to} className="block h-full">
        <div className="flex items-start px-12 py-4">
          <div className="mr-4 flex size-12 items-center justify-center rounded-full bg-[#85BDDB]">{icon}</div>
          <div className="text-sm">
            <p className="mb-[6px] mt-1 text-caption leading-none">{title}</p>
            <p
              data-testid={`${title}/quantity`}
              className="mb-0 text-headline font-semibold leading-none text-primaryTextColor xxs:text-[20px] xs:text-[20px]"
            >
              {quantity}
            </p>
          </div>
        </div>
        {text !== undefined ? (
          <div className="mt-1 flex w-[97%] justify-between px-12">
            <p className="mb-0 text-caption">{text}</p>
          </div>
        ) : null}
      </Link>
    </div>
  )
}
