export default function CoursePending(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" data-testid="coursePendingIcon">
      <g id="course_partial_icon" transform="translate(-204 -1468)">
        <circle
          id="course_pending_icon-2"
          data-name="course_pending_icon"
          cx="6"
          cy="6"
          r="6"
          transform="translate(204 1468)"
          fill="#519ed9"
        />
        <path
          id="Path_25024"
          data-name="Path 25024"
          d="M-3935.206,1475.2l-2.91-1v-3.934"
          transform="translate(4148)"
          fill="none"
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
