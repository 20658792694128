export function ProgramsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.177" height="24" viewBox="0 0 24.177 24">
      <g id="programs_icon" transform="translate(-304.958 -415)">
        <g
          id="Rectangle_3716"
          data-name="Rectangle 3716"
          transform="translate(309 427)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <path d="M0,0H16a0,0,0,0,1,0,0V7a3,3,0,0,1-3,3H3A3,3,0,0,1,0,7V0A0,0,0,0,1,0,0Z" stroke="none" />
          <path d="M2,1H14a1,1,0,0,1,1,1V7a2,2,0,0,1-2,2H3A2,2,0,0,1,1,7V2A1,1,0,0,1,2,1Z" fill="none" />
        </g>
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <path
          id="Path_22466"
          data-name="Path 22466"
          d="M306.956,426.677c1.568.73,6.787,3,7.881,3.434a4.943,4.943,0,0,0,4.068,0c1.568-.73,6.229-2.747,7.754-3.434s1.907-2.618,0-3.734-6.326-3.391-7.754-4.163a3.858,3.858,0,0,0-4.068,0c-1.352.773-6.3,3.133-7.881,4.163S305.389,425.947,306.956,426.677Z"
          transform="translate(0.183)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        />
        <path
          id="Path_22467"
          data-name="Path 22467"
          d="M322.084,423.755c1.344.693,2.1,1.245,3.391,2.017a2.29,2.29,0,0,1,1.331,2.539c0,1.2-.043,2.869,0,4.243"
          className="fill-none stroke-svgColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  )
}
