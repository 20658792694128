import { useField } from 'formik'
import * as React from 'react'
import { DatePicker } from 'react-date-picker'
import { CalendarIcon } from 'src/assets/icons/customIcons/menu-icons/Calendar'
import { formatDatelocale } from 'src/helpers/fns'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useFormData } from './Form'

interface Props {
  readonly className?: string
  readonly name: string
  readonly required?: boolean
  readonly disabled?: boolean
  readonly labelAnimation?: boolean
  readonly label?: string
  readonly minDate?: string | Date | null
  readonly maxDate?: string | Date | null
  readonly instructions?: React.ReactElement
}

export default function DateInput({
  label,
  minDate,
  maxDate,
  disabled,
  instructions,
  className,
  required,
  labelAnimation = false,
  ...props
}: Props): React.ReactElement {
  const t = useTranslatable()
  const [field, meta, helpers] = useField({
    name: props.name,
    validate(value: string | null) {
      if (required === true) {
        if (value == null) {
          return label !== undefined ? `${label} ${t('common:is_required')}` : t('common:field_is_required')
        }
      }
    },
  })
  const [formData] = useFormData()
  const errors = formData.errors.fields[props.name] ?? []
  const isInvalid = (errors.length > 0 || meta.error != null) && meta.touched ? 'invalid' : ''

  const handleChange = React.useCallback((e: Date) => void helpers.setValue(e ?? null, true), [helpers])
  const handleClose = React.useCallback(() => void helpers.setTouched(true, true), [helpers])
  const [dateObject, setDateObject] = React.useState<Date | string>()

  React.useEffect(() => {
    setDateObject(formatDatelocale(field?.value)!)
  }, [field?.value])

  return (
    <div className="mb-3 w-full">
      <div
        className={`flex w-full ${isInvalid} ${labelAnimation ? 'relative' : 'top-label flex-col'}`}
        data-testid={props.name}
      >
        {label != null && (
          <label
            className={`${
              labelAnimation
                ? `absolute left-[12px] z-10 flex-nowrap text-[11px] text-lightPrimaryIconText duration-200 ease-in-out ${
                    field.value != null && field.value !== ''
                      ? 'top-[5%] translate-y-[-10%] text-tiny'
                      : 'top-1/3 translate-y-[-90%]'
                  }`
                : 'mb-2 flex flex-nowrap items-center pl-0 font-bold text-primaryTextColor'
            }`}
            htmlFor={props.name}
          >
            <span>
              {label} {(required ?? false) && <span className="pl-1 text-errorMessages">*</span>}
            </span>
            {instructions}
          </label>
        )}
        <DatePickerComponent
          name={field.name}
          value={dateObject}
          onChange={handleChange}
          onCalendarClose={handleClose}
          minDate={minDate ?? new Date(1900, 0, 1)} // 1 Jan 1900
          maxDate={maxDate}
          disabled={disabled}
          className={`h-[48px] w-full bg-primaryWhite text-primaryTextColor ${
            meta.error != null && meta.touched ? 'errorDate' : ''
          } ${className ?? ''}`}
        />
      </div>
      {meta.touched && meta.error != null ? (
        <div className="mt-2 text-[13px] text-errorMessages">{meta.error}</div>
      ) : null}
      {errors.map((error, i) => (
        <div key={i} className="mt-2 text-[13px] text-errorMessages">
          {error}
        </div>
      ))}
    </div>
  )
}

interface DatePickerProps {
  readonly name: string
  readonly value: Date | string | undefined
  readonly onCalendarClose: () => void
  readonly onChange: (e: Date) => void
  readonly minDate?: string | Date | null
  readonly maxDate?: string | Date | null
  readonly disabled?: boolean
  readonly className: string
}
const DatePickerComponent = React.memo(function DatePickerComponent({
  name,
  value,
  onCalendarClose,
  onChange,
  minDate,
  maxDate,
  ...props
}: DatePickerProps) {
  const t = useTranslatable()

  return (
    <DatePicker
      format="dd/MM/yyyy"
      calendarIcon={<CalendarIcon />}
      locale={t('common:en_gb')}
      name={name}
      value={value != null && value !== '' ? new Date(value) : null}
      onCalendarClose={() => onCalendarClose}
      onChange={(e) => onChange(e as Date)}
      minDate={minDate != null ? new Date(minDate) : new Date(1900, 0, 1)} // minDate გარეშე ყველგან გვაქ ეს ბაგი (https://app.shortcut.com/iliauni-argus/story/11110/)
      maxDate={maxDate != null ? new Date(maxDate) : undefined}
      {...props}
    />
  )
})
