export default function CalendarIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
      <g transform="translate(-305 -415)">
        <g
          fill="none"
          stroke="#000"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth={2}
          data-name="Rectangle 4175"
          transform="translate(305 418)"
        >
          <rect width={24} height={21} stroke="none" rx={4} />
          <rect width={22} height={19} x={1} y={1} rx={3} />
        </g>
        <path className="fill-svgColor" d="M305 426h24v2h-24z" data-name="Rectangle 4176" />
        <rect
          className="fill-svgColor"
          width={2}
          height={8}
          data-name="Rectangle 4177"
          rx={1}
          transform="translate(311 415)"
        />
        <rect
          className="fill-svgColor"
          width={2}
          height={8}
          data-name="Rectangle 4178"
          rx={1}
          transform="translate(321 415)"
        />
      </g>
    </svg>
  )
}
