interface Props {
  readonly width?: string
  readonly height?: string
}

export function XlsIcon({ width = '100%', height = '100%' }: Props): JSX.Element {
  return (
    <svg width={width} height={height} viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M799.8 365.667C799.7 364.15 799.417 362.683 798.9 361.233C798.717 360.7 798.583 360.183 798.35 359.667C797.55 357.95 796.55 356.35 795.167 354.95C795.15 354.917 795.133 354.9 795.1 354.867L700 259.767V166.667C700 166.317 699.817 166 699.8 165.65C699.7 164.167 699.417 162.733 698.933 161.333C698.75 160.8 698.617 160.3 698.383 159.783C697.567 158.017 696.55 156.333 695.117 154.9L545.117 4.9C543.7 3.48333 542.017 2.46667 540.267 1.65C539.733 1.4 539.183 1.25 538.617 1.06667C537.267 0.616667 535.883 0.333333 534.467 0.233333C534.067 0.2 533.717 0 533.333 0H116.667C107.45 0 100 7.46667 100 16.6667V259.767L4.9 354.867C4.86667 354.9 4.86667 354.917 4.83333 354.95C3.45 356.333 2.45 357.95 1.65 359.667C1.41667 360.167 1.28333 360.7 1.1 361.233C0.6 362.667 0.3 364.133 0.2 365.667C0.183333 366.017 0 366.317 0 366.667V683.333C0 692.533 7.45 700 16.6667 700H100V783.333C100 792.533 107.45 800 116.667 800H683.333C692.55 800 700 792.533 700 783.333V700H783.333C792.55 700 800 692.533 800 683.333V366.667C800 366.317 799.817 366.017 799.8 365.667ZM743.1 350H700V306.9L743.1 350ZM643.1 150H550V56.9L643.1 150ZM133.333 33.3333H516.667V166.667C516.667 175.867 524.117 183.333 533.333 183.333H666.667V266.667V350H133.333V266.667V33.3333ZM100 306.9V350H56.9L100 306.9ZM666.667 766.667H133.333V700H666.667V766.667ZM766.667 666.667H33.3333V383.333H116.667H683.333H766.667V666.667Z"
        fill="gray"
      />
      <path d="M406.233 432.733H371.95V633.333H461.483V601.883H406.233V432.733Z" fill="gray" />
      <path
        d="M517.017 464.75C519.467 462.483 522.217 460.833 525.233 459.783C528.25 458.75 531.367 458.233 534.583 458.233C545.733 458.233 554.983 462.667 562.35 471.55L581.05 447.183C575.567 440.767 568.817 435.9 560.8 432.583C552.767 429.283 543.667 427.633 533.467 427.633C526.483 427.633 519.583 428.817 512.783 431.167C505.983 433.533 499.883 437.167 494.5 442.083C489.117 447 484.767 453.183 481.467 460.633C478.167 468.1 476.5 477.033 476.5 487.417C476.5 495.733 477.583 502.85 479.75 508.8C481.917 514.767 484.85 519.95 488.533 524.383C492.217 528.833 496.467 532.65 501.283 535.867C506.1 539.083 511.25 542 516.733 544.65C525.417 549 532.6 553.717 538.267 558.817C543.933 563.917 546.767 570.817 546.767 579.5C546.767 588.383 544.4 595.267 539.683 600.183C534.967 605.1 529.017 607.55 521.833 607.55C515.417 607.55 509.133 606.033 502.983 603.017C496.833 600 491.6 595.833 487.25 590.55L468.833 615.483C474.5 622.1 482.05 627.567 491.5 631.917C500.95 636.267 511.233 638.433 522.383 638.433C530.133 638.433 537.5 637.117 544.483 634.467C551.467 631.817 557.567 627.9 562.767 622.7C567.967 617.517 572.117 611.133 575.233 603.583C578.35 596.033 579.9 587.333 579.9 577.517C579.9 569.017 578.583 561.65 575.933 555.417C573.283 549.183 569.883 543.8 565.733 539.267C561.583 534.733 556.95 530.867 551.85 527.65C546.75 524.433 541.75 521.6 536.833 519.15C528.517 515 521.817 510.6 516.717 505.967C511.617 501.35 509.067 494.783 509.067 486.283C509.067 481.367 509.767 477.117 511.2 473.533C512.617 469.95 514.567 467.017 517.017 464.75Z"
        fill="gray"
      />
      <path
        d="M345.317 432.733H310.467L281 501.867L251.25 432.733H215.833L261.167 527.65L211.017 633.333H246.433L280.15 556.55L313.583 633.333H350.133L299.7 527.65L345.317 432.733Z"
        fill="gray"
      />
    </svg>
  )
}
