interface PlusProps {
  color?: string
  className?: string
}

export function Plus({ color, className }: PlusProps): JSX.Element {
  return (
    <svg
      id="plus_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      className={className}
      data-testid="plusIcon"
    >
      <rect id="Rectangle_161" data-name="Rectangle 161" width="18" height="18" fill="gray" opacity="0" />
      <rect
        id="Rectangle_304"
        data-name="Rectangle 304"
        width="16"
        height="16"
        transform="translate(1 1)"
        fill="gray"
        opacity="0"
      />
      <rect
        id="Rectangle_4222"
        data-name="Rectangle 4222"
        width="1"
        height="12"
        transform="translate(3 9.5) rotate(-90)"
        fill={color}
      />
      <rect
        id="Rectangle_4237"
        data-name="Rectangle 4237"
        width="1"
        height="12"
        transform="translate(8.5 3)"
        fill={color}
      />
    </svg>
  )
}
