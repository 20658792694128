export function CalendarIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="calendar_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          fill="#d6d6d6"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          fill="#d6d6d6"
          opacity="0"
        />
        <g
          id="Rectangle_21"
          data-name="Rectangle 21"
          transform="translate(41 19)"
          fill="none"
          stroke="gray"
          strokeWidth="1"
        >
          <rect width="16" height="14" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="15" height="13" rx="1.5" fill="none" />
        </g>
        <rect
          id="Rectangle_22"
          data-name="Rectangle 22"
          width="16"
          height="1"
          transform="translate(41 24)"
          fill="gray"
        />
        <rect
          id="Rectangle_23"
          data-name="Rectangle 23"
          width="1"
          height="5"
          rx="0.5"
          transform="translate(45 17)"
          fill="gray"
        />
        <rect
          id="Rectangle_24"
          data-name="Rectangle 24"
          width="1"
          height="5"
          rx="0.5"
          transform="translate(52 17)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
