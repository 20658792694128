import { type FormikHelpers } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { type FileState } from 'src/hooks/fileUpload'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import { Form } from 'src/views/components/forms/formik/Form'
import TextInput from 'src/views/components/forms/formik/TextInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

interface FormikValues {
  readonly mentorId: string
  readonly text: string
  readonly id: string
  readonly files: readonly FileState[]
  readonly competencies: []
}
export default function LecturerPortfolioEvaluationPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:evaluation')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const locale = useLocale()
  const params = useParams()
  const navigate = useNavigate()

  const initialValues: FormikValues = {
    id: params.id!,
    mentorId: '',
    text: '',
    files: [],
    competencies: [],
  }

  const { pathname } = useLocation()

  const onSubmit = React.useCallback(
    async (
      { id, mentorId, text, files, competencies }: FormikValues,
      formikHelpers: FormikHelpers<FormikValues>
    ): Promise<void> => {
      try {
        await Api.postStudentPortfolioSectionsWorks({
          headers,
          body: {
            mentorId,
            text,
            competencies,
            files: files.map((e) => e.remote.file!.id),
          },
          args: {
            id,
          },
        })
        toast.success(t('portfolio:work_submitted'))
        formikHelpers.resetForm()
        setSuccessfullySubmited(true)
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    },
    [headers, t]
  )
  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/portfolio/sections/${params.id}/works`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])

  const breadcrumbsItems = [
    { page: `${t('portfolio:portfolio')}`, path: `/${locale}/lecturer/portfolio` },
    { page: `${t('portfolio:portfolio_evaluation')}`, path: pathname },
  ]
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} isConfirmable={!successfullySubmited}>
      <Section title={t('portfolio:portfolio_evaluation')} icon={<Portfolio />} breadcrubms={breadcrumbsItems}>
        <SuspenseWrapper>
          <FormContent />
        </SuspenseWrapper>
      </Section>
    </Form>
  )
}

function FormContent(): React.ReactElement | null {
  const { sectionId } = useParams()
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()

  const lecturerSectionCompetencies = useApi({
    endpoint: Api.getLecturerPortfolioSectionCompetencies,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: sectionId!,
        },
      }),
      [headers, sectionId]
    ),
  })

  return (
    <>
      <div className="mb-6">
        {lecturerSectionCompetencies.data.data.map((c) => (
          <Table.Table className="mb-6 mt-3" key={c.id}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>{c.name}</Table.Th>
                <Table.Th />
                <Table.Th />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {c.criterias?.map((criteria, key) => (
                <Table.Tr key={criteria.id}>
                  <Table.Td className="!align-top">
                    {t('portfolio:criteria')}: {criteria.name}
                  </Table.Td>
                  <Table.Td className="!align-top">
                    <TextInput type="number" name={`criterias.${key}`} required placeholder={t('common:score')} />
                  </Table.Td>
                  <Table.Td className="!pt-2 !align-top">
                    <TextareaInput
                      name={`criterias.${criteria.name}`}
                      placeholder={t('portfolio:comment')}
                      className="className='!mt-0' h-[120px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
                    />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table.Table>
        ))}
      </div>
      <div className="my-3 w-full">
        <TextareaInput
          name="feedback"
          label={t('portfolio:feedback')}
          placeholder={t('portfolio:feedback')}
          className="className='!mt-0' h-[160px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
        />
      </div>
      <div className="my-3 flex w-full justify-end">
        <Button
          type="submit"
          variant="blue"
          // disabled={!formik.isValid || !formik.dirty}
        >
          {t('portfolio:evaluation')}
        </Button>
      </div>
    </>
  )
}
