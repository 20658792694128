export function AnsweredIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="answered_icon" transform="translate(-673 -24)">
        <rect
          id="Rectangle_4302"
          data-name="Rectangle 4302"
          width="12"
          height="12"
          transform="translate(673 24)"
          fill="#fff"
          opacity="0"
        />
        <path
          id="answered_icon-2"
          data-name="answered_icon"
          d="M662.253,23l-6.241,5.5,6.241,5.5V30.172c1.316,0,3.233.213,5.759,2.828-1.469-5.335-4.484-6.312-5.759-6.312Z"
          transform="translate(16.988 1.5)"
          fill="#519ed9"
        />
      </g>
    </svg>
  )
}
