export function ArrowIcon(): JSX.Element {
  return (
    <svg
      id="Group_13"
      data-name="Group 13"
      xmlns="http://www.w3.org/2000/svg"
      width="7.778"
      height="14.142"
      viewBox="0 0 7.778 14.142"
    >
      <rect
        id="Rectangle_27"
        data-name="Rectangle 27"
        width="10"
        height="1"
        transform="translate(7.071 7.778) rotate(-135)"
        className="fill-svgColor"
      />
      <rect
        id="Rectangle_28"
        data-name="Rectangle 28"
        width="10"
        height="1"
        transform="translate(7.778 7.071) rotate(135)"
        className="fill-svgColor"
      />
    </svg>
  )
}
