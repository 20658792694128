export function CourseIcon(): JSX.Element {
  return (
    <svg id="course_icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <g id="Rectangle_4182" data-name="Rectangle 4182" fill="#fff" stroke="#707070" strokeWidth="1" opacity="0">
        <rect width="12" height="12" stroke="none" />
        <rect x="0.5" y="0.5" width="11" height="11" fill="none" />
      </g>
      <g
        id="Rectangle_4181"
        data-name="Rectangle 4181"
        transform="translate(4 2)"
        fill="#fff"
        stroke="gray"
        strokeWidth="1"
      >
        <rect width="8" height="1" stroke="gray" />
        <rect x="0.5" y="0.5" width="7" fill="none" />
      </g>
      <g
        id="Rectangle_4183"
        data-name="Rectangle 4183"
        transform="translate(4 6)"
        fill="#fff"
        stroke="gray"
        strokeWidth="1"
      >
        <rect width="8" height="1" stroke="gray" />
        <rect x="0.5" y="0.5" width="7" fill="none" />
      </g>
      <g
        id="Rectangle_4184"
        data-name="Rectangle 4184"
        transform="translate(4 10)"
        fill="#fff"
        stroke="gray"
        strokeWidth="1"
      >
        <rect width="8" height="1" stroke="gray" />
        <rect x="0.5" y="0.5" width="7" fill="none" />
      </g>
      <g
        id="Ellipse_974"
        data-name="Ellipse 974"
        transform="translate(0 1.5)"
        fill="#fff"
        stroke="gray"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
      <g
        id="Ellipse_975"
        data-name="Ellipse 975"
        transform="translate(0 5.5)"
        fill="#fff"
        stroke="gray"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
      <g
        id="Ellipse_976"
        data-name="Ellipse 976"
        transform="translate(0 9.5)"
        fill="#fff"
        stroke="gray"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
    </svg>
  )
}
