/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { errorMessage } from 'src/helpers/fns'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { Form } from 'src/views/components/forms/formik/Form'
import GroupsTable from './GroupsTable'
import PaperTable from './PaperTable'
import { QueryForm } from './QueryForm'
import StudentsTable from './StudentsTable'

export default function LecturerCoursesScoresPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('common:scores')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}
function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { courseId } = useParams()
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([])
  const t = useTranslatable()
  const semId = searchParams.get('semId')
  const groupId = searchParams.get('groupId')
  const query = searchParams.get('query')
  const edit = searchParams.get('edit')
  const editMultiple = searchParams.get('editMultiple')

  const { data: groupsPaper, isValidating: groupsPaperPending } = useApi({
    endpoint: Api.getLecturerGroupsPaper,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: courseId!,
          groupId: groupId!,
        },
        query: {
          semId: semId!,
        },
      }),
      [courseId, groupId, headers, semId]
    ),
    shouldFetch: semId != null && courseId != null && groupId != null,
    suspense: false,
  })
  const {
    data: filteredStudents,
    isValidating: filteredStudentsPending,
    error: filteredStudentsError,
  }: APIResponse<Api.getLecturerStudentsOk | null, Api.getLecturerStudentsErr> = useApi({
    endpoint: Api.getLecturerStudents,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            search: query!,
          },
          semId: semId!,
        },
        args: { id: courseId! },
      }),
      [courseId, headers, query, semId]
    ),
    shouldFetch: semId != null && courseId != null && query != null && query.length > 0,
    suspense: false,
  })
  const {
    data: teachingGroups,
    isValidating: groupsPending,
    mutate: refetchTeachingGroups,
  } = useApi({
    endpoint: Api.getLecturerTeachingGroups,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: courseId!,
        },
        query: {
          semId: semId!,
        },
      }),
      [courseId, headers, semId]
    ),
    shouldFetch: semId != null && courseId != null,
  })

  const {
    data: courseStudents,
    isValidating: studentPending,
    error: courseStudentsError,
  }: APIResponse<Api.getLecturerStudentsOk | null, Api.getLecturerCoursesStudentsErr> = useApi({
    endpoint: Api.getLecturerCoursesStudents,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          courseId: courseId!,
          groupId: groupId!,
        },
        query: { semId: semId! },
      }),
      [courseId, groupId, headers, semId]
    ),
    shouldFetch: semId != null && courseId != null && groupId != null,
    suspense: false,
  })

  const filteredStudentsGroups = [...new Set(filteredStudents?.map((student) => student.group?.name))]

  // React.useEffect(() => {
  //   if (groupId != null && !studentPending) {
  //     searchParams.delete('groupId')
  //     setSearchParams(searchParams)
  //   }
  //   if (query != null) {
  //     searchParams.delete('query')
  //     setSearchParams(searchParams)
  //   }
  //   if (showPaper) setShowPaper(false)

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [courseId, semId])

  React.useEffect(() => {
    if (edit != null) {
      searchParams.delete('edit')
      setSearchParams(searchParams)
    }
    if (editMultiple != null) {
      searchParams.delete('editMultiple')
      setSearchParams(searchParams)
    }
  }, [groupId])

  // React.useEffect(() => {
  //   if (editMultiple != null && selectedGroups.length > 0 && groupsPaper == null) {
  //     searchParams.delete('editMultiple')
  //     setSearchParams(searchParams)
  //   }
  // }, [selectedGroups])

  const showPaperBtn =
    groupId != null && groupsPaper?.manage?.canEdit !== false && (groupsPaper?.active ?? true) && !groupsPaperPending

  const showMultiplePaperBtn =
    teachingGroups != null && teachingGroups.filter((group) => group.paper == null).length > 1

  const manageEnvironment = (value: boolean, multiple: boolean = false): void => {
    if (value) {
      searchParams.set(multiple ? 'editMultiple' : 'edit', 'true')
    } else {
      searchParams.delete(multiple ? 'editMultiple' : 'edit')
    }
    if (multiple) {
      setSelectedGroups([])
    }

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  return (
    <>
      {(() => {
        if (teachingGroups == null && groupsPending) {
          return <Loader className="m-auto flex" />
        } else if ((teachingGroups == null && !groupsPending) || teachingGroups?.length === 0) {
          return <NoContent header={t('error:records_not_found')} image={Warning} marginTop="5" />
        } else if (teachingGroups !== null) {
          return (
            <>
              {edit == null && editMultiple == null && (
                <Form
                  initialValues={{ query: query ?? '' }}
                  onSubmit={async ({ query }: { query: string }) =>
                    setSearchParams({ semId: searchParams.get('semId')!, query })
                  }
                  classNames={{ form: 'w-full' }}
                  enableReinitialize
                >
                  <QueryForm />
                </Form>
              )}
              {filteredStudents?.length === 0 ||
              (filteredStudents == null && filteredStudentsError != null && !filteredStudentsPending) ? (
                <NoContent
                  header={t('error:records_not_found')}
                  subHeader={filteredStudentsError != null ? errorMessage(filteredStudentsError) : ''}
                  image={Warning}
                  marginTop="5"
                />
              ) : filteredStudentsPending ? (
                <Loader className="mm-auto flex" />
              ) : filteredStudents != null ? (
                filteredStudentsGroups.map((groupName, index) => (
                  <StudentsTable
                    key={index}
                    teachingGroups={teachingGroups}
                    students={filteredStudents.filter((students) => students.group?.name === groupName)}
                    groupsPaper={groupsPaper}
                  />
                ))
              ) : (
                <>
                  <GroupsTable
                    teachingGroups={teachingGroups}
                    groupsPaperPending={groupsPaperPending}
                    groups={selectedGroups}
                    setGroups={setSelectedGroups}
                  />
                  {edit != null || editMultiple != null ? (
                    <div className="mb-2 mt-[15px] flex w-full justify-end">
                      <Button variant="blue" onClick={() => manageEnvironment(false, Boolean(editMultiple))}>
                        {t('uncategorized:return_to_marks')}
                      </Button>
                    </div>
                  ) : showPaperBtn ? (
                    <div className="mb-2 mt-[15px] flex w-full justify-end">
                      <Button variant="blue" onClick={() => manageEnvironment(true)}>
                        {t('manage_evaluation_sheet')}
                      </Button>
                    </div>
                  ) : showMultiplePaperBtn ? (
                    <div className="mb-2 mt-[15px] flex w-full justify-end">
                      <Button variant="blue" onClick={() => manageEnvironment(true, true)}>
                        {t('create_evaluation_sheets')}
                      </Button>
                    </div>
                  ) : null}
                  {edit != null || editMultiple != null ? (
                    <PaperTable
                      groupsPaper={editMultiple != null ? null : groupsPaper}
                      groupsPaperPending={groupsPaperPending}
                      selectedGroups={selectedGroups}
                      refetchTeachingGroups={refetchTeachingGroups}
                    />
                  ) : studentPending ? (
                    <Loader className="m-auto flex" />
                  ) : courseStudents == null && courseStudentsError != null ? (
                    <NoContent
                      header={t('error:records_not_found')}
                      subHeader={errorMessage(courseStudentsError)}
                      image={Warning}
                      marginTop="5"
                    />
                  ) : courseStudents != null ? (
                    <StudentsTable
                      teachingGroups={teachingGroups}
                      students={courseStudents}
                      groupsPaper={groupsPaper}
                    />
                  ) : null}
                </>
              )}
            </>
          )
        }
      })()}
    </>
  )
}
