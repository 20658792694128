export default function CommentIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.002"
      height="11.999"
      viewBox="0 0 12.002 11.999"
      data-testid="commentIcon"
    >
      <g id="comment_icon" transform="translate(0 -0.001)">
        <g id="Union_36" data-name="Union 36" transform="translate(-13015 6509)" fill="none">
          <path
            d="M 13023.5 -6497.8095703125 L 13020.2197265625 -6499.44873046875 L 13020.1142578125 -6499.50146484375 L 13019.99609375 -6499.50146484375 L 13017.0009765625 -6499.50146484375 C 13016.1728515625 -6499.50146484375 13015.5 -6500.17333984375 13015.5 -6500.9990234375 L 13015.5 -6507.00146484375 C 13015.5 -6507.8271484375 13016.1728515625 -6508.4990234375 13017.0009765625 -6508.4990234375 L 13025.0009765625 -6508.4990234375 C 13025.8291015625 -6508.4990234375 13026.501953125 -6507.8271484375 13026.501953125 -6507.00146484375 L 13026.501953125 -6500.9990234375 C 13026.501953125 -6500.17333984375 13025.8291015625 -6499.50146484375 13025.0009765625 -6499.50146484375 L 13024 -6499.50146484375 L 13023.5 -6499.50146484375 L 13023.5 -6499.00146484375 L 13023.5 -6497.8095703125 Z"
            stroke="none"
          />
          <path
            d="M 13023 -6498.6181640625 L 13023 -6500.00146484375 L 13025.0009765625 -6500.00146484375 C 13025.552734375 -6500.00146484375 13026.001953125 -6500.44873046875 13026.001953125 -6500.9990234375 L 13026.001953125 -6507.00146484375 C 13026.001953125 -6507.5517578125 13025.552734375 -6507.9990234375 13025.0009765625 -6507.9990234375 L 13017.0009765625 -6507.9990234375 C 13016.44921875 -6507.9990234375 13016 -6507.5517578125 13016 -6507.00146484375 L 13016 -6500.9990234375 C 13016 -6500.44873046875 13016.44921875 -6500.00146484375 13017.0009765625 -6500.00146484375 L 13020.232421875 -6500.00146484375 L 13023 -6498.6181640625 M 13024 -6497.00048828125 L 13019.99609375 -6499.00146484375 L 13017.0009765625 -6499.00146484375 C 13015.8955078125 -6499.00146484375 13015 -6499.89697265625 13015 -6500.9990234375 L 13015 -6507.00146484375 C 13015 -6508.10302734375 13015.8955078125 -6508.9990234375 13017.0009765625 -6508.9990234375 L 13025.0009765625 -6508.9990234375 C 13026.10546875 -6508.9990234375 13027.001953125 -6508.10302734375 13027.001953125 -6507.00146484375 L 13027.001953125 -6500.9990234375 C 13027.001953125 -6499.89697265625 13026.10546875 -6499.00146484375 13025.0009765625 -6499.00146484375 L 13024 -6499.00146484375 L 13024 -6497.00048828125 Z"
            stroke="none"
            fill="gray"
          />
        </g>
        <rect
          id="Rectangle_4332"
          data-name="Rectangle 4332"
          width="6"
          height="1"
          transform="translate(3 3)"
          fill="gray"
        />
        <rect
          id="Rectangle_4333"
          data-name="Rectangle 4333"
          width="6"
          height="1"
          transform="translate(3 6)"
          fill="gray"
        />
      </g>
    </svg>
  )
}
