export function ScheduleLargeIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="schedule_icon" transform="translate(-305 -415)">
        <g
          id="Rectangle_3664"
          data-name="Rectangle 3664"
          transform="translate(305 415)"
          className="fill-primaryWhite"
          stroke="#707070"
          strokeWidth="1"
          opacity="0"
        >
          <rect width="24" height="24" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" fill="none" />
        </g>
        <g
          id="Rectangle_3665"
          data-name="Rectangle 3665"
          transform="translate(305 417)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <rect width="22" height="20" rx="4" stroke="none" />
          <rect x="1" y="1" width="20" height="18" rx="3" fill="none" />
        </g>
        <g
          id="Ellipse_393"
          data-name="Ellipse 393"
          transform="translate(317 427)"
          className="fill-primaryWhite stroke-svgColor"
          strokeWidth="2"
        >
          <circle cx="6" cy="6" r="6" stroke="none" />
          <circle cx="6" cy="6" r="5" fill="none" />
        </g>
        <path
          id="Path_68"
          data-name="Path 68"
          d="M318.5,429.46v2.452l1.971.8"
          transform="translate(4.323 1.463)"
          fill="none"
          className="stroke-svgColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <rect
          id="Rectangle_3666"
          data-name="Rectangle 3666"
          width="2"
          height="6"
          rx="1"
          transform="translate(310 415)"
          className="fill-svgColor"
        />
        <rect
          id="Rectangle_3667"
          data-name="Rectangle 3667"
          width="2"
          height="6"
          rx="1"
          transform="translate(320 415)"
          className="fill-svgColor"
        />
      </g>
    </svg>
  )
}
